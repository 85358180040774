import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Row, Col, Nav, Tab, Alert } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { useFormik } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import CostCentresComponent from "../components/costcentre_component";
import { OrganizationDetails } from "../services/view_organization";
import { UpdateOrganization } from "../services/update_organization";
import { CreateUser } from "../services/create_user";
import { CreateDashboardInstance } from "../services/create_dashboard_instance";
import { DashboardInstances } from "../services/get_dashboard_instances";
// import { UploadDashboardInstanceFiles } from "../services/upload_dashboard_instance_files";
import { DashboardTemplates } from "../services/get_dashboard_templates";
import { DeleteOrganization, DeleteUser, DeleteDashboardInstance } from "../services/delete_services";
import { RefreshDashboardInstance } from "../services/refresh_dashboard_instance";

export default function ViewOrganization({ companyName, address_line_1, address_line_2, address_state, address_pincode, contact, salesperson }) {

    const [organizationDetails, setOrganizationDetails] = useState({});
    const [organizationUserDetails, setOrganizationUserDetails] = useState([]);
    const [organizationDashboardDetails, setOrganizationDashboardDetails] = useState([]);
    const [itemsPerPage] = useState(5);
    const [currentUserPage, setCurrentUserPage] = useState(1);
    const [currentDashboardPage, setCurrentDashboardPage] = useState(1);
    const [displayedUserPages, setDisplayedUserPages] = useState(5);
    const [displayedDashboardPages, setDisplayedDashboardPages] = useState(5);
    const [changedData, setChangedData] = useState(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showSyncSuccessPopup, setShowSyncSuccessPopup] = useState(false);
    const [template_options, setTemplateOptions] = useState([]);
    const [instance_options, setInstanceOptions] = useState([]);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showAddDbModal, setShowAddDbModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [ogValues, setInitialValues] = useState({ companyName, address_line_1, address_line_2, address_state, address_pincode, contact, salesperson });
    const [editedValues, setEditedValues] = useState({ companyName, address_line_1, address_line_2, address_state, address_pincode, contact, salesperson });
    const [errorMessage, setErrorMessage] = useState("");
    const [userErrorMessage, setUserErrorMessage] = useState("");
    const [dbErrorMessage, setDbErrorMessage] = useState("");
    const [activeTab, setActiveTab] = useState('nav-users');
    const { setValue } = useContext(Context);
    const { updateBreadcrumb } = useContext(Context);
    const history = useHistory();
    const { company_id } = useParams();
    const animatedComponents = makeAnimated();
    const [totalCostCentres, setTotalCostCentres] = useState(0);

    const handleTotalCostCentresChange = (totalCostCentre) => {
        setTotalCostCentres(totalCostCentre);
    };

    const aus_states = [
        "ACT",
        "NSW",
        "NT",
        "QLD",
        "SA",
        "TAS",
        "VIC",
        "WA"
    ];

    const [errors, setErrors] = useState({
        companyName: null,
        address_line_1: null,
        address_line_2: null,
        address_state: null,
        address_pincode: null,
        contact: null,
        salesperson: null,
    });

    const resetAllErrors = () => {
        const updatedErrors = {};
        for (const key in errors) {
            updatedErrors[key] = null;
        }
        setErrors(updatedErrors);
    };

    const handleCloseAddUserModal = () => {
        formik_user.resetForm();
        setUserErrorMessage("");
        setShowAddUserModal(false);
    }
    const handleShowAddUserModal = () => {
        formik_user.resetForm();
        setShowAddUserModal(true);
    }

    const handleCloseAddDbModal = () => {
        formik.resetForm();
        setDbErrorMessage("");
        setShowAddDbModal(false);
    }
    const handleShowAddDbModal = () => {
        formik.resetForm();
        setShowAddDbModal(true);
    }
    
    const handleUserClick = (user_id, company_id) => {
        history.push(`/users/${user_id}/get/?company_id=${company_id}`);
    };

    const handleDashboardClick = (dashboard_id, company_id) => {
        history.push(`/instances/${dashboard_id}/get/?company_id=${company_id}`);
    };

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    const handleEdit = () => {
        setIsEditing(true);
        setErrorMessage("");
    };

    const handleExitEdit = () => {
        setIsEditing(false);
        setErrorMessage("");
        resetAllErrors();
        setEditedValues({
            companyName: ogValues.companyName,
            address_line_1: ogValues.address_line_1 || '',
            address_line_2: ogValues.address_line_2 || '',
            address_state: ogValues.address_state || '',
            address_pincode: ogValues.address_pincode || '',
            contact: ogValues.contact || '',
            salesperson: ogValues.salesperson || '',
        });
    };

    const validationCompanyNameSchema = yup.object().shape({
        companyName: yup.string().required('Name is required')
    });

    const validationAddLine1Schema = yup.object().shape({
        address_line_1: yup.string().required('Address Line 1 is required')
    });

    const validationAddLine2Schema = yup.object().shape({
        address_line_2: yup.string().required('Address Line 2 is required')
    });

    const validationAddStateSchema = yup.object().shape({
        address_state: yup.string().required('State is required')
    });

    const validationAddPincodeSchema = yup.object().shape({
        address_pincode: yup.string().matches(/^[0-9]{4}$/, 'Postcode must be 4 numbers').required("Postcode is required"),
    });

    const validationContactSchema = yup.object().shape({
        contact: yup
        .string()
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid Contact Number')
        .required('Contact Number is required')
    })

    const validationSalespersonSchema = yup.object().shape({
        salesperson: yup.string().required('Lastname is required')
    });

    const handleOrgBlur = async (e) => {
        const { name, value } = e.target;
        try {
            if (name === 'companyName'){
                await validationCompanyNameSchema.validate({ [name]: value });
            }
            if (name === 'address_line_1'){
                await validationAddLine1Schema.validate({ [name]: value });
            }
            if (name === 'address_line_2'){
                await validationAddLine2Schema.validate({ [name]: value });
            }
            if (name === 'address_state'){
                await validationAddStateSchema.validate({ [name]: value });
            }
            if (name === 'address_pincode'){
                await validationAddPincodeSchema.validate({ [name]: value });
            }
            if (name === 'contact'){
                await validationContactSchema.validate({ [name]: value });
            }
            if (name === 'salesperson'){
                await validationSalespersonSchema.validate({ [name]: value });
            }
            setErrors({ ...errors, [name]: null });
        } catch (err) {
            setErrors({ ...errors, [name]: err.errors[0] });
        }
    };

    const handleSave = (editedValues, company_id) => {
        if (!errors.companyName && !errors.address_line_1 && !errors.address_line_2 && !errors.address_state && !errors.address_pincode && !errors.contact && !errors.salesperson){
            setValue({ loading: true });
            UpdateOrganization(company_id, editedValues.companyName, editedValues.address_line_1, editedValues.address_line_2, editedValues.address_state, editedValues.address_pincode, editedValues.contact, editedValues.salesperson)
            .then((data) => {
                if (data.success === true){
                    setIsEditing(false);
                    setValue({ loading: false });
                    setErrorMessage("");
                    resetAllErrors();
                    setInitialValues(editedValues);
                }
                else{
                    setValue({ loading: false });
                    setErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                console.error('Error updating organization:', error);
                setValue({ loading: false });
                setErrorMessage(<Alert variant="danger">{error.message}</Alert>)
            });
        }
    };

    const handleDashboardSync = (instance_id, company_id, template_id) => {
        RefreshDashboardInstance(instance_id, company_id, template_id)
        .then((data) => {
            if (data.success === true) {
                setShowSyncSuccessPopup(true);
                setTimeout(() => {
                    setShowSyncSuccessPopup(false);
                }, 3000);
            }
            else {
                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                setTimeout(() => {
                    setErrorMessage("");
                }, 4000);
            }
        })
    }

    const handleOrganizationDelete = (company_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteOrganization(company_id)
                        .then((data) => {
                            if (data.success === true) {
                                history.push(`/company/get/`);
                            } else {
                                setValue({ loading: false });
                                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Something went wrong");
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    const handleUserDelete = (user_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteUser(user_id, company_id)
                        .then((data) => {
                            if (data.success === true) {
                                setShowSuccessPopup(true);
                                setTimeout(() => {
                                    setShowSuccessPopup(false);
                                }, 2000);
                                if (changedData === true){
                                    setChangedData(false);
                                }
                                else{
                                    setChangedData(true);
                                }
                            } else {
                                setValue({ loading: false });
                                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    const handleDashboardDelete = (dashboard_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteDashboardInstance(dashboard_id, company_id)
                        .then((data) => {
                            if (data.success === true) {
                                setShowSuccessPopup(true);
                                setTimeout(() => {
                                    setShowSuccessPopup(false);
                                }, 2000);
                                if (changedData === true){
                                    setChangedData(false);
                                }
                                else{
                                    setChangedData(true);
                                }
                            } else {
                                setValue({ loading: false });
                                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedValues({ ...editedValues, [name]: value });
    };

    const formik_user = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            contact_number: "",
            access_level: "manager",
            instances: []
        },
        validationSchema: yup.object({
            firstname: yup
            .string()
            .required('Firstname is required'),
            lastname: yup
            .string()
            .required('Lastname is required'),
            email: yup
            .string()
            .email("Enter Valid Email")
            .required('Email is required'),
            contact_number: yup
            .string()
            .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid Contact Number')
            .required('Contact Number is required')
        }),
        onSubmit: (values) => {
            setValue({ loading: true });
            const instance_value_ids = values.instances.length !== 0 ? values.instances.map(item => item.value) : [];
            const create_values = {
                company_id: Number(company_id),
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                contact_number: values.contact_number,
                is_company_admin: values.access_level === 'manager',
                user_accesses: [
                    {
                        "resource_id": 1,
                        "create_rights": false,
                        "read_rights": true,
                        "update_rights": false,
                        "delete_rights": false
                    },
                    {
                        "resource_id": 2,
                        "create_rights": false,
                        "read_rights": true,
                        "update_rights": false,
                        "delete_rights": false
                    },
                    {
                        "resource_id": 3,
                        "create_rights": false,
                        "read_rights": true,
                        "update_rights": false,
                        "delete_rights": false
                    }
                  ],
                  user_instance_accesses: {
                    "instance_ids": instance_value_ids
                  }
            }
            CreateUser(create_values)
            .then((data) => {
                if (data.success === true){
                    setValue({ loading: false });
                    handleCloseAddUserModal();
                    setShowSuccessPopup(true);
                    setTimeout(() => {
                        setShowSuccessPopup(false);
                    }, 2000);
                    if (changedData === true){
                        setChangedData(false);
                    }
                    else{
                        setChangedData(true);
                    }
                }
                else{
                    setValue({ loading: false });
                    setUserErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        },
    });

    const formik = useFormik({
        initialValues: {
            instance_name: "",
            template: ""
        },
        validationSchema: yup.object({
            instance_name: yup
            .string()
            .required('Name is required'),
            template: yup
            .string()
            .required('Template is required')
        }),
        onSubmit: (values) => {
            setValue({ loading: true });
            CreateDashboardInstance(values.instance_name, values.template, company_id)
            .then((data) => {
                if (data.success === true){
                    setValue({ loading: false });
                    handleCloseAddDbModal();
                    setShowSuccessPopup(true);
                    setTimeout(() => {
                        setShowSuccessPopup(false);
                    }, 2000);
                    if (changedData === true){
                        setChangedData(false);
                    }
                    else{
                        setChangedData(true);
                    }
                }
                else{
                    setValue({ loading: false });
                    setDbErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        },
    });

    useEffect(() => {
        setValue({ loading: true });
        // setValue({ breadcrumb_value: "" });
        const fetchData = async () => {
            OrganizationDetails(company_id)
                .then((data) => {
                    setValue({ loading: false });
                    setOrganizationDetails(data);
                    setOrganizationUserDetails(data['users']);
                    setOrganizationDashboardDetails(data['instances']);
                    setInitialValues({
                        companyName: data.company_name,
                        address_line_1: data.address_line_1 || '',
                        address_line_2: data.address_line_2 || '',
                        address_state: data.address_state || '',
                        address_pincode: data.address_pincode || '',
                        contact: data.contact_number || '',
                        salesperson: data.salesperson || '',
                    });
                    setEditedValues({
                        companyName: data.company_name,
                        address_line_1: data.address_line_1 || '',
                        address_line_2: data.address_line_2 || '',
                        address_state: data.address_state || '',
                        address_pincode: data.address_pincode || '',
                        contact: data.contact_number || '',
                        salesperson: data.salesperson || '',
                    });
                    // setValue({ breadcrumb_value: data['company_name'] });
                    const breadcrumb = {
                        name: data['company_name'],
                        link: window.location.pathname,
                    };
                    updateBreadcrumb(breadcrumb);
                })
            .catch((error) => {
                setValue({ loading: false });
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
            DashboardTemplates(company_id)
                .then((data) => {
                    setTemplateOptions(data); 
                })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
            DashboardInstances(company_id)
                .then((data) => {
                    const formattedOptions = data.map((item) => ({
                        value: item.id,
                        label: item.name
                    }));
                    setInstanceOptions(formattedOptions);
                })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        }
        fetchData();
        if (changedData) {
            fetchData();
        }
    }, [changedData]);

    const totalUserPages = Math.ceil(organizationUserDetails.length / itemsPerPage);
    const totalDashboardPages = Math.ceil(organizationDashboardDetails.length / itemsPerPage);
    const startDashboardIndex = (currentDashboardPage - 1) * itemsPerPage;
    const endDashboardIndex = startDashboardIndex + itemsPerPage;
    const startUserIndex = (currentUserPage - 1) * itemsPerPage;
    const endUserIndex = startUserIndex + itemsPerPage;
    const currentUserData = organizationUserDetails.slice(startUserIndex, endUserIndex);
    const currentDashboardData = organizationDashboardDetails.slice(startDashboardIndex, endDashboardIndex);

    const handleUserPageChange = (newPage) => {
        setCurrentUserPage(newPage);

        if (newPage >= displayedUserPages - 1 && newPage <= totalUserPages - displayedUserPages + 2) {
            setDisplayedUserPages((prevPages) => prevPages + (newPage === displayedUserPages - 1 ? 0 : 1));
        }
    };

    const handleDashboardPageChange = (newPage) => {
        setCurrentDashboardPage(newPage);

        if (newPage >= displayedDashboardPages - 1 && newPage <= totalDashboardPages - displayedDashboardPages + 2) {
            setDisplayedDashboardPages((prevPages) => prevPages + (newPage === displayedDashboardPages - 1 ? 0 : 1));
        }
    };

    const renderUserPagination = () => {
        const userPageNumbers = [];
        for (let i = 1; i <= totalUserPages; i++) {
            userPageNumbers.push(i);
        }

        return (
            <div className="pagination-main">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link prev-btn" onClick={() => {
                        if (currentUserPage > 1) {
                            handleUserPageChange(currentUserPage - 1);
                        }
                    }}></a>
                </li>
                {currentUserPage > Math.floor(displayedUserPages / 2) + 1 && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleUserPageChange(1)}>
                      1
                    </a>
                  </li>
                )}
                {currentUserPage > Math.floor(displayedUserPages / 2) + 2 && <li className="page-item">...</li>}
                {userPageNumbers
                  .filter(
                    (pageNumber) =>
                      pageNumber >= currentUserPage - Math.floor(displayedUserPages / 2) &&
                      pageNumber <= currentUserPage + Math.floor(displayedUserPages / 2)
                  )
                  .map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${pageNumber === currentUserPage ? 'active' : ''}`}
                    >
                      <a className="page-link" onClick={() => handleUserPageChange(pageNumber)}>
                        {pageNumber}
                      </a>
                    </li>
                  ))}
                {currentUserPage < totalUserPages - Math.floor(displayedUserPages / 2) - 1 && <li className="page-item">...</li>}
                {currentUserPage < totalUserPages - Math.floor(displayedUserPages / 2) && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleUserPageChange(totalUserPages)}>
                      {totalUserPages}
                    </a>
                  </li>
                )}
                <li className="page-item">
                    <a className="page-link next-btn" onClick={() => {
                        if (currentUserPage < totalUserPages) {
                            handleUserPageChange(currentUserPage + 1);
                        }
                    }}></a>
                </li>
              </ul>
            </div>
        );
    };

    const renderDashboardPagination = () => {
        const dashboardPageNumbers = [];
        for (let i = 1; i <= totalDashboardPages; i++) {
            dashboardPageNumbers.push(i);
        }

        return (
            <div className="pagination-main">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link prev-btn" onClick={() => {
                        if (currentDashboardPage > 1) {
                            handleDashboardPageChange(currentDashboardPage - 1);
                        }
                    }}></a>
                </li>
                {currentDashboardPage > Math.floor(displayedDashboardPages / 2) + 1 && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleDashboardPageChange(1)}>
                      1
                    </a>
                  </li>
                )}
                {currentDashboardPage > Math.floor(displayedDashboardPages / 2) + 2 && <li className="page-item">...</li>}
                {dashboardPageNumbers
                  .filter(
                    (pageNumber) =>
                      pageNumber >= currentDashboardPage - Math.floor(displayedDashboardPages / 2) &&
                      pageNumber <= currentDashboardPage + Math.floor(displayedDashboardPages / 2)
                  )
                  .map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${pageNumber === currentDashboardPage ? 'active' : ''}`}
                    >
                      <a className="page-link" onClick={() => handleDashboardPageChange(pageNumber)}>
                        {pageNumber}
                      </a>
                    </li>
                  ))}
                {currentDashboardPage < totalDashboardPages - Math.floor(displayedDashboardPages / 2) - 1 && <li className="page-item">...</li>}
                {currentDashboardPage < totalDashboardPages - Math.floor(displayedDashboardPages / 2) && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleDashboardPageChange(totalDashboardPages)}>
                      {totalDashboardPages}
                    </a>
                  </li>
                )}
                <li className="page-item">
                    <a className="page-link next-btn" onClick={() => {
                        if (currentDashboardPage < totalDashboardPages) {
                            handleDashboardPageChange(currentDashboardPage + 1);
                        }
                    }}></a>
                </li>
              </ul>
            </div>
        );
    };

    return (
        <Layout activeMenuItem="organizations">
           <div className="dashboard-content">
                <div className="responsive main-table add-organization view-organization">
                    {showSuccessPopup && (
                        <Alert variant="success">Success</Alert>
                    )}
                    {showSyncSuccessPopup && (
                        <Alert variant="success">Refresh initiated. Please check the dashboard after few minutes.</Alert>
                    )}
                    {errorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">{organizationDetails['company_name']}</h2>
                            <div className="view-org-btn-groups">
                                {/* <button type="btn" className="common-btn edit-btn">
                                    <img src="/assets/img/edit-icon.png" alt="edit" /><span
                                        >Edit</span
                                        >
                                </button> */}
                                {isEditing ? (
                                    <button className="common-btn save-btn" onClick={() => handleSave(editedValues, organizationDetails['id'])}>Save</button>
                                ) : (
                                    <button className="common-btn edit-btn" onClick={handleEdit}>
                                        <img src="/assets/img/edit-icon.png" alt="edit" />
                                        <span>Edit</span>
                                    </button>
                                )}
                                {isEditing ? (
                                    <button className="common-btn white-btn cancel-btn" onClick={handleExitEdit}>Cancel</button>
                                ) : (
                                    <button onClick={() => handleOrganizationDelete(organizationDetails['id'])} type="btn" className="common-btn delete-btn">
                                        <img src="/assets/img/delete-icon.png" alt="edit" /><span
                                            >Delete</span
                                            >
                                    </button>
                                )}
                            </div>
                    </div>
                    <div className="add-org-main">
                        <div className="add-dashboard-top">
                            <div className="row">
                                <div className="col-12">
                                    <div className="view-organization-top">
                                        <div className="org-data-info">
                                            <table>
                                                <thead className="cf">
                                                    <tr>
                                                        <th>Organization Name</th>
                                                        <th>Contact</th>
                                                        <th>Salesperson</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Organization Name">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="companyName"
                                                                value={editedValues.companyName}
                                                                onChange={handleInputChange}
                                                                onBlur={handleOrgBlur}
                                                                style={{ borderColor: errors.companyName ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedValues.companyName
                                                            )}
                                                            {isEditing && errors.companyName ? (<div className="validation_error" style={{ color: 'red' }}>{errors.companyName}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Contact">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="contact"
                                                                value={editedValues.contact}
                                                                onChange={handleInputChange}
                                                                onBlur={handleOrgBlur}
                                                                style={{ borderColor: errors.contact ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedValues.contact !== null ? editedValues.contact : ''
                                                            )}
                                                            {isEditing && errors.contact ? (<div className="validation_error" style={{ color: 'red' }}>{errors.contact}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Salesperson">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="salesperson"
                                                                value={editedValues.salesperson}
                                                                onChange={handleInputChange}
                                                                onBlur={handleOrgBlur}
                                                                style={{ borderColor: errors.salesperson ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedValues.salesperson !== null ? editedValues.salesperson : ''
                                                            )}
                                                            {isEditing && errors.salesperson ? (<div className="validation_error" style={{ color: 'red' }}>{errors.salesperson}</div>) : ('')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table>
                                                <thead className="cf">
                                                    <tr>
                                                        <th>Address Line 1</th>
                                                        <th>Address Line 2</th>
                                                        <th>State</th>
                                                        <th>Postcode</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Address Line 1">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="address_line_1"
                                                                value={editedValues.address_line_1}
                                                                onChange={handleInputChange}
                                                                onBlur={handleOrgBlur}
                                                                style={{ borderColor: errors.address_line_1 ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedValues.address_line_1 !== null ? editedValues.address_line_1 : ''
                                                            )}
                                                            {isEditing && errors.address_line_1 ? (<div className="validation_error" style={{ color: 'red' }}>{errors.address_line_1}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Address Line 2">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="address_line_2"
                                                                value={editedValues.address_line_2}
                                                                onChange={handleInputChange}
                                                                onBlur={handleOrgBlur}
                                                                style={{ borderColor: errors.address_line_2 ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedValues.address_line_2 !== null ? editedValues.address_line_2 : ''
                                                            )}
                                                            {isEditing && errors.address_line_2 ? (<div className="validation_error" style={{ color: 'red' }}>{errors.address_line_2}</div>) : ('')}
                                                        </td>
                                                        <td data-title="State">
                                                            {isEditing ? (
                                                                <select
                                                                    id="address_state"
                                                                    name="address_state"
                                                                    value={editedValues.address_state}
                                                                    onChange={handleInputChange}
                                                                    onBlur={handleOrgBlur}
                                                                    style={{ borderColor: errors.address_state ? 'red' : '' }}
                                                                >
                                                                    <option value="">Select State</option>
                                                                    {aus_states.map((state) => (
                                                                        <option key={state} value={state}>
                                                                            {state}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                editedValues.address_state !== null ? editedValues.address_state : ''
                                                            )}
                                                            {isEditing && errors.address_state ? (<div className="validation_error" style={{ color: 'red' }}>{errors.address_state}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Pincode">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="address_pincode"
                                                                value={editedValues.address_pincode}
                                                                onChange={handleInputChange}
                                                                onBlur={handleOrgBlur}
                                                                style={{ borderColor: errors.address_pincode ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedValues.address_pincode !== null ? editedValues.address_pincode : ''
                                                            )}
                                                            {isEditing && errors.address_pincode ? (<div className="validation_error" style={{ color: 'red' }}>{errors.address_pincode}</div>) : ('')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="add-dashboard-main view-organization-main">
                            <Nav className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                <Nav.Item>
                                    <Nav.Link
                                    className={`nav-link ${activeTab === 'nav-users' ? 'active' : ''}`}
                                    id="nav-users-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-users"
                                    onClick={() => handleTabSelect('nav-users')}
                                    role="tab"
                                    aria-controls="nav-users"
                                    aria-selected={activeTab === 'nav-users'}
                                    >
                                        Users ({organizationUserDetails.length})
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                    className={`nav-link ${activeTab === 'nav-dashboard' ? 'active' : ''}`}
                                    id="nav-dashboard-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-dashboard"
                                    onClick={() => handleTabSelect('nav-dashboard')}
                                    role="tab"
                                    aria-controls="nav-dashboard"
                                    aria-selected={activeTab === 'nav-dashboard'}
                                    >
                                        Dashboards ({organizationDashboardDetails.length})
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                    className={`nav-link ${activeTab === 'nav-costcentre' ? 'active' : ''}`}
                                    id="nav-costcentre-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-costcentre"
                                    onClick={() => handleTabSelect('nav-costcentre')}
                                    role="tab"
                                    aria-controls="nav-costcentre"
                                    aria-selected={activeTab === 'nav-costcentre'}
                                    >
                                        Cost Centres ({totalCostCentres})
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="tab-content" id="nav-add-org">
                                <Tab.Pane className={`tab-pane fade ${activeTab === 'nav-users' ? 'active show' : ''}`}
                                    id="nav-users"
                                    role="tabpanel"
                                    aria-labelledby="nav-users-tab"
                                >
                                    <div className="add-user-btn">
                                        <button type="button" onClick={handleShowAddUserModal} className="common-btn">
                                            + Add User
                                        </button>
                                    </div>
                                    <Modal
                                    show={showAddUserModal}
                                    onHide={handleCloseAddUserModal}
                                    centered
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add User</Modal.Title>
                                        </Modal.Header>
                                        {userErrorMessage}
                                        <Modal.Body>
                                            <Form noValidate onSubmit={formik_user.handleSubmit}>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="firstname">Firstname*</Form.Label>
                                                            <Form.Control
                                                                id="firstname"
                                                                type="text"
                                                                placeholder="Enter First Name"
                                                                name="firstname"
                                                                onChange={formik_user.handleChange}
                                                                onBlur={formik_user.handleBlur}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik_user.errors.firstname}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="lastname">Lastname*</Form.Label>
                                                            <Form.Control
                                                                id="lastname"
                                                                type="text"
                                                                placeholder="Enter Last Name"
                                                                name="lastname"
                                                                onChange={formik_user.handleChange}
                                                                onBlur={formik_user.handleBlur}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik_user.errors.lastname}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="email">Email*</Form.Label>
                                                            <Form.Control
                                                                id="email"
                                                                type="text"
                                                                placeholder="Enter Email"
                                                                name="email"
                                                                onChange={formik_user.handleChange}
                                                                onBlur={formik_user.handleBlur}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik_user.errors.email}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="contact_number">Contact Number*</Form.Label>
                                                            <Form.Control
                                                                id="contact_number"
                                                                type="text"
                                                                placeholder="Enter Contact Number"
                                                                name="contact_number"
                                                                onChange={formik_user.handleChange}
                                                                onBlur={formik_user.handleBlur}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik_user.errors.contact_number}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Access Level:</Form.Label>
                                                            <div className="d-flex align-items-center flex-wrap">
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="manager"
                                                                    name="access_level"
                                                                    value="manager"
                                                                    label="Manager"
                                                                    onChange={formik_user.handleChange}
                                                                    onBlur={formik_user.handleBlur}
                                                                    checked={formik_user.values.access_level === 'manager'}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="normal_user"
                                                                    name="access_level"
                                                                    value="normal_user"
                                                                    onChange={formik_user.handleChange}
                                                                    onBlur={formik_user.handleBlur}
                                                                    checked={formik_user.values.access_level === 'normal_user'}
                                                                    label="User"
                                                                />
                                                            </div>
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik_user.errors.access_level}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    {formik_user.values.access_level === 'normal_user' && (
                                                        <Col lg={12}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="instances">Assign Dashboards</Form.Label>
                                                                <Select
                                                                    components={animatedComponents}
                                                                    name="instances"
                                                                    isMulti
                                                                    options={instance_options}
                                                                    value={formik_user.values.instances}
                                                                    onChange={(selectedOptions) => {
                                                                        formik_user.setFieldValue('instances', selectedOptions);
                                                                    }}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik_user.errors.instances}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    )}
                                                </Row>
                                                <div className="d-flex align-items-center justify-content-center w-100">
                                                    <div className="btn-groups">
                                                        <button type="submit" className="common-btn">
                                                            Save
                                                        </button>
                                                        <button
                                                            className="common-btn white-btn"
                                                            onClick={handleCloseAddUserModal}
                                                            >
                                                        Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Modal.Body>
                                    </Modal>
                                    <div className="table-responsive">
                                        <table className="col-md-12 table">
                                            <thead className="cf">
                                                <tr>
                                                    <th>User Name</th>
                                                    <th>Email</th>
                                                    <th>Contact</th>
                                                    <th>Access Level</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentUserData.map((user, index) => (
                                                    <tr className="clickable_row" data-user-id={user.id} key={user.id}
                                                        onClick={(e) => {
                                                            if (!e.target.classList.contains('delete-button')) {
                                                                handleUserClick(user.id, user.company_id)
                                                            }
                                                        }}>
                                                        <td data-title="User Name">{user.firstname} {user.lastname}</td>
                                                        <td data-title="Email">{user.email}</td>
                                                        <td data-title="Contact">{user.contact_number !== null ? user.contact_number : ''}</td>
                                                        <td data-title="Access Level">{user.is_company_admin === true ? 'Manager' : 'User'}</td>
                                                        <td data-title="Action">
                                                            <div className="action-btns">
                                                                {/* <button>
                                                                    <img src="/assets/img/edit-icon.png" alt="" />
                                                                </button> */}
                                                                <button onClick={() => handleUserDelete(user.id)} className="delete-button">
                                                                    <img src="/assets/img/delete-icon.png" alt="" className="delete-button"/>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {renderUserPagination()}
                                </Tab.Pane>
                                <Tab.Pane className={`tab-pane fade ${activeTab === 'nav-dashboard' ? 'active show' : ''}`}
                                    id="nav-dashboard"
                                    role="tabpanel"
                                    aria-labelledby="nav-dashboard-tab"
                                >
                                    <div className="add-user-btn">
                                        <button
                                            type="button"
                                            className="common-btn"
                                            onClick={handleShowAddDbModal}
                                        >
                                            + Add Dashboard
                                        </button>
                                    </div>
                                    <Modal
                                    show={showAddDbModal}
                                    onHide={handleCloseAddDbModal}
                                    centered
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add Dashboard</Modal.Title>
                                        </Modal.Header>
                                        {dbErrorMessage}
                                        <Modal.Body>
                                            <Form noValidate onSubmit={formik.handleSubmit}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="instance-name">Name*</Form.Label>
                                                            <Form.Control
                                                                id="instance-name"
                                                                type="text"
                                                                placeholder="Enter Name"
                                                                name="instance_name"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.instance_name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="template">Template*</Form.Label>
                                                            <Form.Select
                                                                id="template"
                                                                name="template"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                aria-label="Select Template"
                                                            >
                                                                <option value="">Select Template</option>
                                                                {template_options.map((option) => (
                                                                    <option key={option.id} value={option.id}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.template}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex align-items-center justify-content-center w-100">
                                                    <div className="btn-groups">
                                                        <button type="submit" className="common-btn">
                                                            Save
                                                        </button>
                                                        <button
                                                            className="common-btn white-btn"
                                                            onClick={handleCloseAddDbModal}
                                                            >
                                                        Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Modal.Body>
                                    </Modal>
                                    <div className="table-responsive">
                                        <table className="col-md-12 table">
                                            <thead className="cf">
                                                <tr>
                                                    <th>Dashboard Name</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentDashboardData.map((dashboard, index) => (
                                                    <tr className="clickable_row" data-dashboard-id={dashboard.id} key={dashboard.id}
                                                        onClick={(e) => {
                                                            if (!e.target.classList.contains('delete-button') && !e.target.classList.contains('sync-button')) {
                                                                handleDashboardClick(dashboard.id, dashboard.company_id)
                                                            }
                                                        }}>
                                                        <td data-title="Dashboard Name">{dashboard.name}</td>
                                                        <td data-title="Action">
                                                            <div className="action-btns">
                                                                <button onClick={() => handleDashboardSync(dashboard.id, dashboard.company_id, dashboard.template_id)} className="sync-button">
                                                                    <img src="/assets/img/sync-icon.png" alt="" className="sync-button"/>
                                                                </button>
                                                                <button onClick={() => handleDashboardDelete(dashboard.id)} className="delete-button">
                                                                    <img src="/assets/img/delete-icon.png" alt="" className="delete-button"/>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {renderDashboardPagination()}
                                </Tab.Pane>
                                <Tab.Pane className={`tab-pane fade ${activeTab === 'nav-costcentre' ? 'active show' : ''}`}
                                    id="nav-costcentre"
                                    role="tabpanel"
                                    aria-labelledby="nav-costcentre-tab"
                                >
                                    <CostCentresComponent companyid={company_id} items={5} onTotalCostCentreChange={handleTotalCostCentresChange}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
