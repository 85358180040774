import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const DashboardInstanceDetails = async (instance_id ,company_id) =>{
    
    
    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var dashboard_instance_details = [];
    axios.defaults.baseURL = config.servers["base"];
    let urlTOload = `/instances/${instance_id}/get/`
    
    await axios.post(
        urlTOload,
        {
            "company_id": company_id
        },
        {
            headers:{
                authorization : "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        dashboard_instance_details = response.data;
        
        

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            dashboard_instance_details = [] 
        }
    })
    return await dashboard_instance_details

}