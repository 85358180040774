import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const OrganizationsList = async () =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var organization_list = [];
    axios.defaults.baseURL = config.servers["base"];
    let urlTOload = "company/get/"
    
    await axios.get(
        urlTOload,
        {
            headers:{
                authorization : "Bearer " + access_token 
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        organization_list = response.data;
        
        

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            organization_list = [] 
        }
    })
    return await organization_list

}