import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const CostCentreList = async (company_id, page, items_per_page, search_service_number, search_username) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var costcentres = [];
    axios.defaults.baseURL = config.servers["base"];
    let urlTOload = `costcentres/get/`
    
    await axios.post(
        urlTOload,
        {
            company_id: company_id,
            page: page,
            items_per_page: items_per_page,
            search_service_number: search_service_number,
            search_username: search_username
        },
        {
            headers:{
                authorization : "Bearer " + access_token 
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        costcentres = response.data;
    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            costcentres = [] 
        }
    })
    return await costcentres

}

export const UpdateCostCentre = async (cost_centre_id, cost_centre_name, comments, company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    var body = {
        "company_id": company_id
    }
    if (cost_centre_name !== null) {
        body.cost_centre = cost_centre_name;
    }
    if (comments !== null) {
        body.comments = comments;
    }
    
    await axios.put(
        `costcentres/${cost_centre_id}/update_costcentre/`,
        body,
        {
            headers:{
                authorization : "Bearer " + access_token ,
                "Content-Type": "application/json",
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}