import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab, Alert, Form } from 'react-bootstrap';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as yup from "yup";

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { TemplateDetails } from "../services/view_dashboard_template";
import { UpdateTemplate } from "../services/update_dashboard_template";
import { OrganizationsList } from "../services/organizations";
import { DeleteDashboardTemplate } from "../services/delete_services";

export default function ViewDashboardTemplate({ template_name, workspace_id, report_id, dataset_id }) {

    const [templateDetails, setTemplateDetails] = useState({});
    const [userDashboardInstanceDetails, setUserDashboardInstanceDetails] = useState([]);
    // const [template_options, setTemplateOptions] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [ogValues, setInitialValues] = useState({ template_name, workspace_id, report_id, dataset_id });
    const [editedTemplateValues, setEditedTemplateValues] = useState({ template_name, workspace_id, report_id, dataset_id });
    const [company_options, setCompanyOptions] = useState([]);
    // const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    // const [activeTab, setActiveTab] = useState('nav-files');
    const { setValue } = useContext(Context);
    const { updateBreadcrumb } = useContext(Context);
    const { template_id } = useParams();
    const history = useHistory();
    const animatedComponents = makeAnimated();

    const [errors, setErrors] = useState({
        template_name: null,
        workspace_id: null,
        report_id: null,
        dataset_id: null
    });

    const resetAllErrors = () => {
        const updatedErrors = {};
        for (const key in errors) {
            updatedErrors[key] = null;
        }
        setErrors(updatedErrors);
    };

    const handleEdit = () => {
        setIsEditing(true);
        setErrorMessage("");
    };

    const handleExitEdit = () => {
        setIsEditing(false);
        setErrorMessage("");
        resetAllErrors();
        setEditedTemplateValues({
            template_name: ogValues.template_name,
            workspace_id: ogValues.workspace_id,
            report_id: ogValues.report_id,
            dataset_id: ogValues.dataset_id,
            company_ids: ogValues.company_ids
        });
    };

    const validationNameSchema = yup.object().shape({
        template_name: yup.string().required('Template Name is required')
    });

    const validationWorkspaceSchema = yup.object().shape({
        workspace_id: yup.string().required('Workspace Id is required')
    });

    const validationDashboardSchema = yup.object().shape({
        report_id: yup.string().required('Dashboard Id is required')
    });

    const validationDatasetSchema = yup.object().shape({
        dataset_id: yup.string().required('Dataset Id is required')
    })

    const handleTemplateBlur = async (e) => {
        const { name, value } = e.target;
        try {
            if (name === 'template_name'){
                await validationNameSchema.validate({ [name]: value });
            }
            if (name === 'workspace_id'){
                await validationWorkspaceSchema.validate({ [name]: value });
            }
            if (name === 'report_id'){
                await validationDashboardSchema.validate({ [name]: value });
            }
            if (name === 'dataset_id'){
                await validationDatasetSchema.validate({ [name]: value });
            }
            setErrors({ ...errors, [name]: null });
        } catch (err) {
            setErrors({ ...errors, [name]: err.errors[0] });
        }
    };

    const handleSave = (editedTemplateValues, template_id) => {
        if (!errors.template_name && !errors.workspace_id && !errors.report_id && !errors.dataset_id){
            setValue({ loading: true });
            UpdateTemplate(template_id, editedTemplateValues.template_name, editedTemplateValues.workspace_id, editedTemplateValues.report_id, editedTemplateValues.dataset_id, editedTemplateValues.company_ids)
            .then((data) => {
                if (data.success === true){
                    setIsEditing(false);
                    setValue({ loading: false });
                    setErrorMessage("");
                    resetAllErrors();
                    setInitialValues(editedTemplateValues);
                }
                else{
                    setValue({ loading: false });
                    setErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                console.error('Error updating template:', error);
                setValue({ loading: false });
                setErrorMessage(<Alert variant="danger">{error.message}</Alert>)
            });
        }
    };

    const handleTemplateDelete = (template_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteDashboardTemplate(template_id)
                        .then((data) => {
                            if (data.success === true) {
                                // setShowSuccessPopup(true);
                                // setTimeout(() => {
                                //     setShowSuccessPopup(false);
                                // }, 2000);
                                history.push(`/templates/get/`);
                            } else {
                                setValue({ loading: false });
                                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Something went wrong");
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedTemplateValues({ ...editedTemplateValues, [name]: value });
    };

    const handleSelectOptionChange = (selected) => {
        const selectedValues = selected.map((option) => option.value);
        setEditedTemplateValues({
            ...editedTemplateValues,
            company_ids: selectedValues
        })
    }

    useEffect(() => {
        setValue({ loading: true });
        // setValue({ breadcrumb_value: "" });
        TemplateDetails(template_id)
            .then((data) => {
                setTemplateDetails(data);
                setValue({ loading: false });
                const formattedCompanyData = data['companies'].map(item => item.id);
                setInitialValues({
                    template_name: data.name,
                    workspace_id: data.dashboard_workspace_id,
                    report_id: data.dashboard_report_id,
                    dataset_id: data.dashboard_dataset_id,
                    company_ids: formattedCompanyData
                });
                setEditedTemplateValues({
                    template_name: data.name,
                    workspace_id: data.dashboard_workspace_id,
                    report_id: data.dashboard_report_id,
                    dataset_id: data.dashboard_dataset_id,
                    company_ids: formattedCompanyData
                });
                // setValue({ breadcrumb_value: data['template_name'] + ' ' + data['workspace_id'] });
                // updateBreadcrumb(data['name']);
                const breadcrumb = {
                    name: data['name'],
                    link: window.location.pathname,
                };
                updateBreadcrumb(breadcrumb);
                // setSelectedCompanyOptions(formattedInstanceData)
            })
            .catch((error) => {
                setValue({ loading: false });
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        OrganizationsList()
            .then((data) => {
                const formattedOptions = data.map((item) => ({
                    value: item.id,
                    label: item.company_name
                }));
                setCompanyOptions(formattedOptions);
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
    }, []);

    return (
        <Layout activeMenuItem="templates">
           <div className="dashboard-content">
                <div className="responsive main-table add-organization view-organization">
                    {errorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">{templateDetails['name']}</h2>
                        <div className="view-org-btn-groups">
                            {isEditing ? (
                                <button className="common-btn save-btn" onClick={() => handleSave(editedTemplateValues, templateDetails['id'])}>Save</button>
                            ) : (
                                <button className="common-btn edit-btn" onClick={handleEdit}>
                                    <img src="/assets/img/edit-icon.png" alt="edit" />
                                    <span>Edit</span>
                                </button>
                            )}
                            {isEditing ? (
                                <button className="common-btn white-btn cancel-btn" onClick={handleExitEdit}>Cancel</button>
                            ) : (
                                <button onClick={() => handleTemplateDelete(templateDetails['id'])} type="btn" className="common-btn delete-btn">
                                    <img src="/assets/img/delete-icon.png" alt="edit" /><span
                                        >Delete</span
                                        >
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="add-org-main">
                        <div className="add-dashboard-top">
                            <div className="row">
                                <div className="col-12">
                                    <div className="view-organization-top">
                                        <div className="org-data-info">
                                            <table>
                                                <thead className="cf">
                                                    <tr>
                                                        <th>Template Name</th>
                                                        <th>Workspace Id</th>
                                                        <th>Dashboard Id</th>
                                                        <th>Dataset Id</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Template Name">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="template_name"
                                                                value={editedTemplateValues.template_name}
                                                                onChange={handleInputChange}
                                                                onBlur={handleTemplateBlur}
                                                                style={{ borderColor: errors.template_name ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedTemplateValues.template_name
                                                            )}
                                                            {isEditing && errors.template_name ? (<div className="validation_error" style={{ color: 'red' }}>{errors.template_name}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Workspace Id">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="workspace_id"
                                                                value={editedTemplateValues.workspace_id}
                                                                onChange={handleInputChange}
                                                                onBlur={handleTemplateBlur}
                                                                style={{ borderColor: errors.workspace_id ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                <span className="long-text"> {editedTemplateValues.workspace_id} </span>
                                                            )}
                                                            {isEditing && errors.workspace_id ? (<div className="validation_error" style={{ color: 'red' }}>{errors.workspace_id}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Dashboard Id">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="report_id"
                                                                value={editedTemplateValues.report_id}
                                                                onChange={handleInputChange}
                                                                onBlur={handleTemplateBlur}
                                                                style={{ borderColor: errors.report_id ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                <span className="long-text"> {editedTemplateValues.report_id}</span>
                                                            )}
                                                            {isEditing && errors.report_id ? (<div className="validation_error" style={{ color: 'red' }}>{errors.report_id}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Dataset Id">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="dataset_id"
                                                                value={editedTemplateValues.dataset_id}
                                                                onChange={handleInputChange}
                                                                onBlur={handleTemplateBlur}
                                                                style={{ borderColor: errors.dataset_id ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                <span className="long-text">{editedTemplateValues.dataset_id}</span>
                                                            )}
                                                            {isEditing && errors.dataset_id ? (<div className="validation_error" style={{ color: 'red' }}>{errors.dataset_id}</div>) : ('')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                    </div>
                                    <div className="view-organization-top">
                                        <div className="org-data-info">
                                            <table className="org_table">
                                                <thead className="cf">
                                                    <tr>
                                                        <th>Organizations</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {editedTemplateValues['company_ids'] !== undefined && (
                                                                <Select
                                                                    components={animatedComponents}
                                                                    name="company_ids"
                                                                    isMulti
                                                                    isClearable
                                                                    options={company_options}
                                                                    value={company_options.filter((option) =>
                                                                    editedTemplateValues['company_ids'].includes(option.value)
                                                                    )}
                                                                    onChange={handleSelectOptionChange}
                                                                    isDisabled={!isEditing}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="add-dashboard-main view-organization-main">
                            {/* <div className="pagination-main">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link prev-btn" href="#"></a>
                                    </li>
                                    <li className="page-item"><a className="page-link active" href="#">1</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">2</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">...</a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">10</a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link next-btn" href="#"></a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}