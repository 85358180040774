import axios from "axios";
import Cookies from "./cookies";
import config from "../config";
const qs = require('qs');

export default class xhr {
    constructor(props) {
        let {
            url,
            data = {},
            page = 1,
            perPage,
            server = "base",
        } = props;

        this.url = url
        const CookieObj = new Cookies();
        let newData = data;
        if (typeof data === "string") {
            const parsedObject = qs.parse(data);
            parsedObject.token = CookieObj.getCookie("token");
            newData = qs.stringify(parsedObject);
        } else {
            newData.token = CookieObj.getCookie("token");
        }
        this.data = newData;
        this.page = page;
        this.perPage = perPage;
        axios.defaults.baseURL = config.servers[server];
    }

    handleExpiredToken = () => {
        window.location = "/";
    };

    sendRequest = (props, cb) => {
        let url = props.url ?? this.url;
        try {
            axios({
                method: props.method,
                url,
                params: props.method === "get" ? this.data : {},
                data: props.method !== "get" ? this.data : {},
//                headers: {
//                    'Content-Type': 'application/x-www-form-urlencoded'
//                },
            })
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        response.data.status = true;
                        cb(response.data);
                    } else {
                        if (response.status === 401) {
                            this.handleExpiredToken();
                        } else {
                            console.error(response.data.note);
                            cb(response.data);
                        }
                    }
                })
                .catch((e) => {
                    if (e.response && e.response.data.detail) {
                        cb({ status: false, note: e.response.data.detail });
                    } else {
                        cb({
                            status: false,
                            note: "Connecting to the server failed",
                        });
                    }
                });
        } catch (e) {
            console.error(e);
            cb({ status: false, note: "Internal Error" });
        }
    };

    GetOne = (callback) => {
        this.sendRequest({ method: "get" }, (res) => {
            if (res.status) {
                callback(res.data.item);
            } else {
                callback(res);
            }
        });
    };

    GetMany = (callback) => {
        if (this.page > 0) {
            this.data.page_size = this.perPage;
            this.data.page = this.page;
        } else {
            this.data.page_size = 1000000;
            this.data.page = 1;
        }
        this.sendRequest({ method: "get" }, callback);
    };

    Post = (callback) => {
        this.sendRequest({ method: "post" }, callback);
    };

    Put = (callback) => {
        this.sendRequest({ method: "put" }, callback);
    };
}
