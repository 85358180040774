import React, { useEffect, useState } from "react";
// import { Row } from "react-bootstrap";
import Header from "./header";
import SideBar from "./sidebar";

// import SideBarInternal from "./sidebar-internal";

export default function Layout(props) {
    // const [activeMenuItem, setActiveMenuItem] = useState('');
    useEffect(() => {
        const sidebarToggle = document.querySelector('.sidebar-toggle');
        const sidebar = document.querySelector('nav');
        const header = document.querySelector('.dashboard-top');
        const toggleClass = 'is-sticky';
    
        sidebarToggle.addEventListener('click', () => {
          sidebar.classList.toggle('close');
        });
    
        window.addEventListener('scroll', () => {
          const currentScroll = window.pageYOffset;
          if (currentScroll > 1) {
            header.classList.add(toggleClass);
          } else {
            header.classList.remove(toggleClass);
          }
        });
    }, []);
    
    return (
        <>
            <SideBar activeMenuItem={props.activeMenuItem}></SideBar>
            <div className="dashboard">
                <Header></Header>
                {props.children}
            </div>
            {/* <div className="container-fluid mt-3">
                <Row>
                    <SideBarInternal></SideBarInternal>
                    <div className="col-sm-12 main">{props.children}</div>
                </Row>
            </div> */}
        </>
    );
}
