import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const UploadDashboardInstanceFiles = async (instance_id, file, month, year, company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('company_id', company_id); // Update with the correct company_id
    formData.append('month', month); // Update with the correct month
    formData.append('year', year); // Update with the correct year
    
    await axios.post(
        `instances/${instance_id}/upload/file/`,
        formData,
        {
            headers:{
                authorization : "Bearer " + access_token ,
                "Content-Type": "multipart/form-data",
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}