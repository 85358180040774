import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Form, Row, Col, Alert } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import { useFormik } from "formik";
import * as yup from "yup";

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { OrganizationsList } from "../services/organizations";
import { CreateOrganization } from "../services/create_organization";
import { DeleteOrganization } from "../services/delete_services";

export default function Organizations() {
    const [organizationList, setOrganizationList] = useState([]);
    const [itemsPerPage] = useState(10);
    const [currentOrganizationsPage, setCurrentOrganizationsPage] = useState(1);
    const [displayedOrganizationsPages, setDisplayedOrganizationsPages] = useState(5);
    const [changedData, setChangedData] = useState(null);
    const [showAddOrgModal, setShowAddOrgModal] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [deleteerrorMessage, setDeleteErrorMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { setValue, user_role, companyid, updateBreadcrumb } = useContext(Context);
    const history = useHistory();

    const aus_states = [
        "ACT",
        "NSW",
        "NT",
        "QLD",
        "SA",
        "TAS",
        "VIC",
        "WA"
    ];

    const handleCloseAddOrgModal = () => {
        formik.resetForm();
        setShowAddOrgModal(false);
    }
    const handleShowAddOrgModal = () => {
        formik.resetForm();
        setShowAddOrgModal(true);
    }

    const handleCompanyClick = (companyId) => {
        history.push(`/company/${companyId}/get/`);
    };

    const handleDelete = (company_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteOrganization(company_id)
                        .then((data) => {
                            if (data.success === true) {
                                setShowSuccessPopup(true);
                                setTimeout(() => {
                                    setShowSuccessPopup(false);
                                }, 2000);
                                if (changedData === true){
                                    setChangedData(false);
                                }
                                else{
                                    setChangedData(true);
                                }
                            } else {
                                setValue({ loading: false });
                                setDeleteErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Something went wrong");
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    const formik = useFormik({
        initialValues: {
            company_name: "",
            address_line_1: "",
            address_line_2: "",
            address_state: "",
            address_pincode: "",
            contact_number: "",
            salesperson: "",
        },
        validationSchema: yup.object({
            company_name: yup.string().required("Organization Name is required"),
            address_line_1: yup.string().required("Address Lines is required"),
            address_line_2: yup.string().required("Address Lines is required"),
            address_state: yup.string().required("State is required"),
            address_pincode: yup.string().matches(/^[0-9]{4}$/, 'Postcode must be 4 numbers').required("Postcode is required"),
            contact_number: yup.string().matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid Contact').required("Contact is required"),
            salesperson: yup.string().required("Salesperson is required"),
        }),
        onSubmit: (values) => {
            setValue({ loading: true });
            CreateOrganization(
                values.company_name,
                values.address_line_1,
                values.address_line_2,
                values.address_state,
                values.address_pincode,
                values.contact_number,
                values.salesperson
            )
            .then((data) => {
            if (data.success === true) {
                setValue({ loading: false });
                handleCloseAddOrgModal();
                setShowSuccessPopup(true);
                setTimeout(() => {
                    setShowSuccessPopup(false);
                }, 2000);
                if (changedData === true){
                    setChangedData(false);
                }
                else{
                    setChangedData(true);
                }
            } else {
                setValue({ loading: false });
                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
            }
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ", error);
            });
        },
    });

    useEffect(() => {
        setValue({ breadcrumb_value: [{name:'Organizations', link: '/company/get/'}] });
        setValue({ loading: true });
        // updateBreadcrumb({name:'Organizations', link: '/company/get/'});

        const fetchData = async () => {
            OrganizationsList()
            .then((data) => {
                setOrganizationList(data);
                setValue({ loading: false });
            })
            .catch((error) => {
                alert("Something went wrong while fetching Data");
            });
        };
        fetchData();
        if (changedData) {
            fetchData();
        }
    }, [changedData]);

    const totalOrganizationsPages = Math.ceil(organizationList.length / itemsPerPage);
    const startOrganizationsIndex = (currentOrganizationsPage - 1) * itemsPerPage;
    const endOrganizationsIndex = startOrganizationsIndex + itemsPerPage;
    const currentOrganizationsData = organizationList.slice(startOrganizationsIndex, endOrganizationsIndex);

    const handleOrganizationsPageChange = (newPage) => {
        setCurrentOrganizationsPage(newPage);

        if (newPage >= displayedOrganizationsPages - 1 && newPage <= totalOrganizationsPages - displayedOrganizationsPages + 2) {
            setDisplayedOrganizationsPages((prevPages) => prevPages + (newPage === displayedOrganizationsPages - 1 ? 0 : 1));
        }
    };

    const renderOrganizationsPagination = () => {
        const organizationsPageNumbers = [];
        for (let i = 1; i <= totalOrganizationsPages; i++) {
            organizationsPageNumbers.push(i);
        }

        return (
            <div className="pagination-main">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link prev-btn" onClick={() => {
                        if (currentOrganizationsPage > 1) {
                            handleOrganizationsPageChange(currentOrganizationsPage - 1);
                        }
                    }}></a>
                </li>
                {currentOrganizationsPage > Math.floor(displayedOrganizationsPages / 2) + 1 && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleOrganizationsPageChange(1)}>
                      1
                    </a>
                  </li>
                )}
                {currentOrganizationsPage > Math.floor(displayedOrganizationsPages / 2) + 2 && <li className="page-item">...</li>}
                {organizationsPageNumbers
                  .filter(
                    (pageNumber) =>
                      pageNumber >= currentOrganizationsPage - Math.floor(displayedOrganizationsPages / 2) &&
                      pageNumber <= currentOrganizationsPage + Math.floor(displayedOrganizationsPages / 2)
                  )
                  .map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${pageNumber === currentOrganizationsPage ? 'active' : ''}`}
                    >
                      <a className="page-link" onClick={() => handleOrganizationsPageChange(pageNumber)}>
                        {pageNumber}
                      </a>
                    </li>
                  ))}
                {currentOrganizationsPage < totalOrganizationsPages - Math.floor(displayedOrganizationsPages / 2) - 1 && <li className="page-item">...</li>}
                {currentOrganizationsPage < totalOrganizationsPages - Math.floor(displayedOrganizationsPages / 2) && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleOrganizationsPageChange(totalOrganizationsPages)}>
                      {totalOrganizationsPages}
                    </a>
                  </li>
                )}
                <li className="page-item">
                    <a className="page-link next-btn" onClick={() => {
                        if (currentOrganizationsPage < totalOrganizationsPages) {
                            handleOrganizationsPageChange(currentOrganizationsPage + 1);
                        }
                    }}></a>
                </li>
              </ul>
            </div>
        );
    };

    return (
        <Layout activeMenuItem="organizations">
            <div className="dashboard-content">
                <div className="responsive main-table">
                    {showSuccessPopup && (
                        <Alert variant="success">Success</Alert>
                    )}
                    {deleteerrorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">Organizations</h2>
                        <div className="add-user-btn">
                            <button
                                type="button"
                                className="common-btn"
                                onClick={handleShowAddOrgModal}
                            >
                                + Add Organization
                            </button>
                            <Modal
                                show={showAddOrgModal}
                                onHide={handleCloseAddOrgModal}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Organization</Modal.Title>
                                </Modal.Header>
                                {errorMessage}
                                <Modal.Body>
                                    <Form noValidate onSubmit={formik.handleSubmit}>
                                        <Row>
                                            <Col lg={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="company-name">
                                                        Organization Name*
                                                    </Form.Label>
                                                    <Form.Control
                                                        id="company-name"
                                                        type="text"
                                                        placeholder="Enter Organization Name"
                                                        name="company_name"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.company_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="address_line_1">Address*</Form.Label>
                                                    <Form.Control
                                                        id="address_line_1"
                                                        type="textarea"
                                                        placeholder="Enter Address Line 1"
                                                        name="address_line_1"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        rows={3}
                                                    /><br/>
                                                    <Form.Control
                                                        id="address_line_2"
                                                        type="textarea"
                                                        placeholder="Enter Address Line 2"
                                                        name="address_line_2"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        rows={3}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.address_line_1}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="form-group">
                                                    {/* <Form.Label htmlFor="address_state">State*</Form.Label> */}
                                                    <Form.Select
                                                        id="address_state"
                                                        name="address_state"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.address_state}
                                                    >
                                                        <option value="">Select State</option>
                                                        {aus_states.map((state) => (
                                                            <option key={state} value={state}>
                                                            {state}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.address_state}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="form-group">
                                                    {/* <Form.Label htmlFor="address_pincode">Pincode*</Form.Label> */}
                                                    <Form.Control
                                                        id="address_pincode"
                                                        type="text"
                                                        placeholder="Enter Postcode"
                                                        name="address_pincode"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.address_pincode}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="form-group">
                                                <Form.Label htmlFor="contact_number">
                                                    Contact Number*
                                                </Form.Label>
                                                <Form.Control
                                                    id="contact-number"
                                                    type="text"
                                                    placeholder="Enter Contact Number"
                                                    name="contact_number"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.contact_number}
                                                </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="form-group">
                                                <Form.Label htmlFor="salesperson">
                                                    Salesperson*
                                                </Form.Label>
                                                <Form.Control
                                                    id="salesperson"
                                                    type="text"
                                                    placeholder="Enter Salesperson"
                                                    name="salesperson"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.salesperson}
                                                </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <div className="d-flex align-items-center justify-content-center w-100">
                                        <div className="btn-groups">
                                            <button type="submit" className="common-btn">
                                            Save
                                            </button>
                                            <button
                                            className="common-btn white-btn"
                                            onClick={handleCloseAddOrgModal}
                                            >
                                            Cancel
                                            </button>
                                        </div>
                                        </div>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="col-md-12 table">
                            <thead className="cf">
                                <tr>
                                <th>Organization Name</th>
                                <th className="numeric">Users</th>
                                <th className="numeric">Dashboards</th>
                                <th>Salesperson</th>
                                <th className="numeric"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentOrganizationsData.map((org, index) => (
                                    <tr
                                        className="clickable_row"
                                        data-company-id={org.id}
                                        key={org.id}
                                        onClick={(e) => {
                                            if (!e.target.classList.contains('delete-button')) {
                                                handleCompanyClick(org.id);
                                            }
                                        }}
                                    >
                                        <td data-title="Organization Name">{org.company_name}</td>
                                        <td data-title="Users" className="numeric">
                                        {org.user_counts}
                                        </td>
                                        <td data-title="Dashboards" className="numeric">
                                        {org.instance_counts}
                                        </td>
                                        <td data-title="Salesperson">
                                        {org.salesperson !== null ? org.salesperson : ""}
                                        </td>
                                        <td data-title="Action" className="numeric">
                                        <div className="action-btns">
                                            {/* <button>
                                                                    <img src="/assets/img/ic-view-all.png" alt="" />
                                                                </button> */}
                                            <button onClick={() => handleDelete(org.id)} className="delete-button">
                                                <img src="/assets/img/delete-icon.png" alt="" className="delete-button"/>
                                            </button>
                                        </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {renderOrganizationsPagination()}
                </div>
            </div>
        </Layout>
    );
}
