import React, { useContext, useEffect, useState } from "react";

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import CostCentresComponent from "../components/costcentre_component";

export default function CostCentresPage() {
    const [errorMessage, setErrorMessage] = useState("");

    const { setValue, companyid } = useContext(Context);


    useEffect(() => {
        setValue({ breadcrumb_value: [{name:'Cost Centres', link: '/costcentres/get/'}] });
    }, []);

    return (
        <Layout activeMenuItem="costcentres">
            <div className="dashboard-content">
                <div className="responsive main-table">
                    {errorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">Cost Centres</h2>
                    </div>
                    <CostCentresComponent companyid={companyid} items={10} />
                </div>
            </div>
        </Layout>
    );
}
