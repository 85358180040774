import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const DeleteOrganization = async (company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.delete(
        `company/${company_id}/delete/`,
        {
            headers:{
                authorization : "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}

export const DeleteUser = async (user_id, company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.delete(
        `users/${user_id}/delete/`,
        {
            headers:{
                authorization : "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            data: {
                "company_id": company_id,
            },
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}

export const DeleteDashboardInstance = async (dashboard_id, company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.delete(
        `instances/${dashboard_id}/delete/`,
        {
            headers:{
                authorization : "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            data: {
                "company_id": company_id,
            },
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}

export const DeleteDashboardInstanceFile = async (dashboard_id, file_id, company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.delete(
        `instances/${dashboard_id}/remove/file/${file_id}/`,
        {
            headers:{
                authorization : "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            data: {
                "company_id": company_id,
            },
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}

export const DeleteDashboardTemplate = async (template_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.delete(
        `templates/${template_id}/delete/`,
        {
            headers:{
                authorization : "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}