import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const UpdateTemplate = async (template_id, template_name, workspace_id, report_id, dataset_id, company_ids) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.put(
        `templates/${template_id}/update/`,
        {
            "name": template_name,
            "dashboard_workspace_id": workspace_id,
            "dashboard_report_id": report_id,
            "dashboard_dataset_id": dataset_id,
            "company_ids": company_ids
        },
        {
            headers:{
                authorization : "Bearer " + access_token ,
                "Content-Type": "application/json",
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}