import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Context } from "../providers/mainProvider";
import Breadcrumb from "../components/breadcrumb";
import { useHistory } from "react-router-dom";

export default function Header(props) {
  const { logout, setValue, breadcrumb_value } = useContext(Context);
  const history = useHistory();

  // function setSidebarShowTrue() {
  //     setValue({
  //         sidebar_hide: true,
  //     });
  // }

  // function getFeedback(){
  //     history.push("/feedback")
  // }

  return (
    <div className="dashboard-top">
      <div className="dashboard-top-left">
        <div className="sidebar-toggle">
          <img
            src="/assets/img/toggle-menu.png"
            width="28"
            height="28"
            alt="toggle menu"
          />
        </div>
        {breadcrumb_value.length === 0 ? (
          <></>
        ) : (
          <div className="breadcums">
            <Breadcrumb currentPage={breadcrumb_value}></Breadcrumb>
          </div>
        )}
      </div>
      <div className="dashboard-menu-right">
        <ul>
          <li className="profile">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdownnotification">
                <img src="/assets/img/profile-icon.png" alt="Profile" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/users/me/">My Profile</Dropdown.Item>
                <Dropdown.Item href="#" onClick={logout}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <div className="dropdown">
                            <button
                            className="btn dropdown-toggle"
                            type="button"
                            id="dropdownnotification"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            >
                                <img src="/assets/img/profile-icon.png" alt="Profile" />
                            </button>
                            <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownnotification"
                            >
                                <li><a className="dropdown-item" href="#">My Profile</a></li>
                                <li><a className="dropdown-item" href="#" onClick={logout}>Logout</a></li>
                            </ul>
                        </div> */}
          </li>
        </ul>
      </div>
    </div>
    // <Nav
    //     className="navbar navbar-expand-md fixed-top"
    //     style={{ marginBottom: "20px" }}>
    //     <div className="container-fluid">
    //         <div className="w-100 header_row">
    //             <div className="header-left">
    //                 <div className="top-header">
    //                     <button
    //                         className="btn float-end"
    //                         data-bs-toggle="offcanvas"
    //                         data-bs-target="#offcanvas"
    //                         role="button"
    //                         onClick={setSidebarShowTrue}>
    //                         <img src="/assets/img/menu.png" alt="sidebar" />
    //                     </button>
    //                 </div>
    //                 {breadcrumb_value === "" ? (
    //                     <></>
    //                 ) : (
    //                     <div className="breadcums">
    //                         <Breadcrumb
    //                             currentPage={breadcrumb_value}></Breadcrumb>
    //                     </div>
    //                 )}
    //             </div>

    //             <div className="header-right">
    //                 <div className="navbar_items">
    //                     <div className="d-none d-md-block">
    //                         <ul>
    //                             <li>
    //                                 <div className="version">
    //                                     <p>Alpha v0.1</p>
    //                                 </div>
    //                             </li>
    //                             <li>
    //                                 <div
    //                                     className="nav_help"
    //                                     onClick={showHelp}
    //                                     style={{ cursor: "pointer" }}>
    //                                     <div className="icon">
    //                                         <img
    //                                             src="/assets/img/ic-help-blue.png"
    //                                             alt=""
    //                                         />
    //                                     </div>
    //                                     <div className="title">
    //                                         <p>Help</p>
    //                                     </div>
    //                                 </div>
    //                             </li>
    //                             <li style={{cursor : "pointer"}} onClick={getFeedback}>
    //                                 <div className="nav_help">
    //                                     <div className="icon">
    //                                         <img
    //                                             src="/assets/img/ic-feedback-blue.png"
    //                                             alt=""
    //                                         />
    //                                     </div>
    //                                     <div className="title">
    //                                         <p>Give Feedback</p>
    //                                     </div>
    //                                 </div>
    //                             </li>
    //                             <li
    //                                 style={{ cursor: "pointer" }}
    //                                 onClick={logout}>
    //                                 <div className="nav_help">
    //                                     <div className="icon">
    //                                         <img
    //                                             src="/assets/img/ic-logout-blue.png"
    //                                             alt=""
    //                                         />
    //                                     </div>
    //                                     <div className="title">
    //                                         <p>Logout</p>
    //                                     </div>
    //                                 </div>
    //                             </li>
    //                         </ul>
    //                     </div>
    //                 </div>
    //                 <div className="navbar_items">
    //                     <div className="d-md-none">
    //                         <ul>
    //                             <li
    //                                 style={{ cursor: "pointer" }}
    //                                 onClick={logout}>
    //                                 <div className="nav_help">
    //                                     <div className="icon">
    //                                         <img
    //                                             src="/assets/img/ic-logout-blue.png"
    //                                             alt=""
    //                                         />
    //                                     </div>
    //                                     <div className="title">
    //                                         <p>Logout</p>
    //                                     </div>
    //                                 </div>
    //                             </li>
    //                         </ul>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </Nav>
  );
}
