import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const UsersList = async (company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var users = [];
    axios.defaults.baseURL = config.servers["base"];
    let urlTOload = `users/get/`
    
    await axios.post(
        urlTOload,
        {
            company_id: company_id
        },
        {
            headers:{
                authorization : "Bearer " + access_token 
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        users = response.data;
    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            users = [] 
        }
    })
    return await users

}