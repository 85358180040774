import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab, Alert, Form } from 'react-bootstrap';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as yup from "yup";

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { UserDetails } from "../services/view_user";
import { UpdateUser } from "../services/update_user";
import { DashboardInstances } from "../services/get_dashboard_instances";
import { DeleteUser } from "../services/delete_services";

export default function ViewUser({ firstname, lastname, contact_number, is_company_admin }) {

    const [userDetails, setUserDetails] = useState({});
    const [userDashboardInstanceDetails, setUserDashboardInstanceDetails] = useState([]);
    // const [template_options, setTemplateOptions] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [ogValues, setInitialValues] = useState({ firstname, lastname, contact_number, is_company_admin });
    const [editedUserValues, setEditedUserValues] = useState({ firstname, lastname, contact_number, is_company_admin });
    const [instance_options, setInstanceOptions] = useState([]);
    const [selectedInstanceOptions, setSelectedInstanceOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    // const [activeTab, setActiveTab] = useState('nav-files');
    const { setValue, user_role, companyid } = useContext(Context);
    const { updateBreadcrumb } = useContext(Context);
    const { user_id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const company_id = user_role === 'superuser' ? searchParams.get('company_id') : companyid;
    const history = useHistory();
    const animatedComponents = makeAnimated();

    const [errors, setErrors] = useState({
        firstname: null,
        lastname: null,
        email: null,
        contact_number: null,
    });

    const resetAllErrors = () => {
        const updatedErrors = {};
        for (const key in errors) {
            updatedErrors[key] = null;
        }
        setErrors(updatedErrors);
    };

    const handleEdit = () => {
        setErrorMessage("");
        setIsEditing(true);
    };

    const handleExitEdit = () => {
        setIsEditing(false);
        setErrorMessage("");
        resetAllErrors();
        setEditedUserValues({
            firstname: ogValues.firstname,
            lastname: ogValues.lastname,
            email: ogValues.email,
            contact_number: ogValues.contact_number,
            access_level: ogValues.access_level,
            instances: ogValues.instances
        });
    };

    const validationFnameSchema = yup.object().shape({
        firstname: yup.string().required('Firstname is required')
    });

    const validationLnameSchema = yup.object().shape({
        lastname: yup.string().required('Lastname is required')
    });

    const validationEmailSchema = yup.object().shape({
        email: yup.string().email('Enter Valid Email').required('Email is required')
    });

    const validationContactSchema = yup.object().shape({
        contact_number: yup
        .string()
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid Contact Number')
        .required('Contact Number is required')
    })

    const handleBlur = async (e) => {
        const { name, value } = e.target;
        try {
            if (name === 'firstname'){
                await validationFnameSchema.validate({ [name]: value });
            }
            if (name === 'lastname'){
                await validationLnameSchema.validate({ [name]: value });
            }
            if (name === 'email'){
                await validationEmailSchema.validate({ [name]: value });
            }
            if (name === 'contact_number'){
                await validationContactSchema.validate({ [name]: value });
            }
            setErrors({ ...errors, [name]: null });
        } catch (err) {
            setErrors({ ...errors, [name]: err.errors[0] });
        }
    };

    const handleSave = (editedUserValues, user_id, company_id) => {
        if (!errors.firstname && !errors.lastname && !errors.email && !errors.contact_number){
            setValue({ loading: true });
            UpdateUser(user_id, editedUserValues.firstname, editedUserValues.lastname, editedUserValues.email, editedUserValues.contact_number, editedUserValues.access_level === 'manager', editedUserValues.instances, company_id)
            .then((data) => {
                if (data.success === true){
                    setIsEditing(false);
                    setValue({ loading: false });
                    setErrorMessage("");
                    resetAllErrors();
                    setInitialValues(editedUserValues);
                }
                else{
                    setValue({ loading: false });
                    setErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                console.error('Error updating user:', error);
                setValue({ loading: false });
                setErrorMessage(<Alert variant="danger">{error.message}</Alert>)
            });
        }
    };

    const handleUserDelete = (user_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteUser(user_id, company_id)
                        .then((data) => {
                            if (data.success === true) {
                                // setShowSuccessPopup(true);
                                // setTimeout(() => {
                                //     setShowSuccessPopup(false);
                                // }, 2000);
                                history.push(`/company/${company_id}/get/`);
                            } else {
                                setValue({ loading: false });
                                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUserValues({ ...editedUserValues, [name]: value });
    };

    const handleSelectOptionChange = (selected) => {
        const selectedValues = selected.map((option) => option.value);
        setEditedUserValues({
            ...editedUserValues,
            instances: selectedValues
        })
    }

    useEffect(() => {
        setValue({ loading: true });
        // setValue({ breadcrumb_value: "" });
        UserDetails(user_id, company_id)
            .then((data) => {
                setUserDetails(data);
                setValue({ loading: false });
                // setUserDashboardInstanceDetails(data['user_instance_accesses']);
                // setOrganizationDashboardDetails(data['instances']);
                const formattedInstanceData = data['user_instance_accesses'].map(item => item.instance_id);
                setInitialValues({
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    contact_number: data.contact_number,
                    access_level: data.is_company_admin ? 'manager' : 'normal_user',
                    instances: formattedInstanceData
                });
                setEditedUserValues({
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    contact_number: data.contact_number,
                    access_level: data.is_company_admin ? 'manager' : 'normal_user',
                    instances: formattedInstanceData
                });
                // setValue({ breadcrumb_value: data['firstname'] + ' ' + data['lastname'] });
                // updateBreadcrumb(data['firstname'] + ' ' + data['lastname']);
                const breadcrumb = {
                    name: data['firstname'] + ' ' + data['lastname'],
                    link: window.location.pathname + window.location.search,
                };
                updateBreadcrumb(breadcrumb);
                setSelectedInstanceOptions(formattedInstanceData)
            })
            .catch((error) => {
                setValue({ loading: false });
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        DashboardInstances(company_id)
            .then((data) => {
                const formattedOptions = data.map((item) => ({
                    value: item.id,
                    label: item.name
                }));
                setInstanceOptions(formattedOptions);
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        // DashboardTemplates(company_id)
        //     .then((data) => {
        //         setTemplateOptions(data); 
        //     })
        //     .catch((error) => {
        //         console.log("Something went wrong");
        //         console.log("Error: ",error);
        //     });
    }, []);

    return (
        <Layout activeMenuItem={user_role === "superuser" ? "organizations" : "users"}>
           <div className="dashboard-content">
                <div className="responsive main-table add-organization view-organization">
                    {errorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">{userDetails['firstname']} {userDetails['lastname']}</h2>
                        <div className="view-org-btn-groups">
                            {isEditing ? (
                                <button className="common-btn save-btn" onClick={() => handleSave(editedUserValues, userDetails['id'], userDetails['company_id'])}>Save</button>
                            ) : (
                                <button className="common-btn edit-btn" onClick={handleEdit}>
                                    <img src="/assets/img/edit-icon.png" alt="edit" />
                                    <span>Edit</span>
                                </button>
                            )}
                            {isEditing ? (
                                <button className="common-btn white-btn cancel-btn" onClick={handleExitEdit}>Cancel</button>
                            ) : (
                                <button onClick={() => handleUserDelete(userDetails['id'])} type="btn" className="common-btn delete-btn">
                                    <img src="/assets/img/delete-icon.png" alt="edit" /><span
                                        >Delete</span
                                        >
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="add-org-main">
                        <div className="add-dashboard-top">
                            <div className="row">
                                <div className="col-12">
                                    <div className="view-organization-top">
                                        <div className="org-data-info">
                                            <table>
                                                <thead className="cf">
                                                    <tr>
                                                        <th>Firstname</th>
                                                        <th>Lastname</th>
                                                        <th>Email</th>
                                                        <th>Contact</th>
                                                        <th>Access level</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Firstname">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="firstname"
                                                                value={editedUserValues.firstname}
                                                                onChange={handleInputChange}
                                                                onBlur={handleBlur}
                                                                style={{ borderColor: errors.firstname ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedUserValues.firstname
                                                            )}
                                                            {isEditing && errors.firstname ? (<div className="validation_error" style={{ color: 'red' }}>{errors.firstname}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Lastname">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="lastname"
                                                                value={editedUserValues.lastname}
                                                                onChange={handleInputChange}
                                                                onBlur={handleBlur}
                                                                style={{ borderColor: errors.lastname ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedUserValues.lastname
                                                            )}
                                                            {isEditing && errors.lastname ? (<div className="validation_error" style={{ color: 'red' }}>{errors.lastname}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Email">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="email"
                                                                value={editedUserValues.email}
                                                                onChange={handleInputChange}
                                                                onBlur={handleBlur}
                                                                style={{ borderColor: errors.email ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedUserValues.email
                                                            )}
                                                            {isEditing && errors.email ? (<div className="validation_error" style={{ color: 'red' }}>{errors.email}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Contact Number">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="contact_number"
                                                                value={editedUserValues.contact_number}
                                                                onChange={handleInputChange}
                                                                onBlur={handleBlur}
                                                                style={{ borderColor: errors.contact_number ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedUserValues.contact_number
                                                            )}
                                                            {isEditing && errors.contact_number ? (<div className="validation_error" style={{ color: 'red' }}>{errors.contact_number}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Access Level">
                                                            {isEditing ? (
                                                                <div className="radios-group">
                                                                    <Form.Check
                                                                    type="radio"
                                                                    id="manager"
                                                                    name="access_level"
                                                                    value="manager"
                                                                    label="Manager"
                                                                    checked={editedUserValues.access_level === 'manager'}
                                                                    onChange={handleInputChange}
                                                                    disabled={!isEditing}
                                                                    
                                                                    />
                                                                    <Form.Check
                                                                    type="radio"
                                                                    id="normal_user"
                                                                    name="access_level"
                                                                    value="normal_user"
                                                                    label="User"
                                                                    checked={editedUserValues.access_level === 'normal_user'}
                                                                    onChange={handleInputChange}
                                                                    disabled={!isEditing}
                                                                    
                                                                    />
                                                                </div>
                                                                ) : (
                                                                    editedUserValues.access_level === 'manager' ? 'Manager' : 'User'
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {editedUserValues.access_level === 'normal_user' && (
                                        <div className="view-organization-top">
                                            <div className="org-data-info">
                                                <table className="org_table">
                                                    <thead className="cf">
                                                        <tr>
                                                            <th>Assigned Dashboards</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                            {editedUserValues['instances'] !== undefined && (
                                                                <Select
                                                                    components={animatedComponents}
                                                                    name="instances"
                                                                    isMulti
                                                                    isClearable
                                                                    options={instance_options}
                                                                    value={
                                                                        instance_options.filter(option => 
                                                                            editedUserValues['instances'].includes(option.value))
                                                                    }
                                                                    onChange={handleSelectOptionChange}
                                                                    isDisabled={!isEditing}
                                                                />
                                                            )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="add-dashboard-main view-organization-main">
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}