import React, { useContext, useEffect, useState } from "react";
import { Alert } from 'react-bootstrap';
import { useParams, useLocation } from "react-router-dom";

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { GetDashboardEmbedData } from "../services/get_dashboard_instance_embed_token";

export default function DisplayDashboardInstance() {

    const [errorMessage, setErrorMessage] = useState("");
    const { setValue, user_role, companyid, updateBreadcrumb } = useContext(Context);
    const { instance_id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const company_id = user_role === 'superuser' ? searchParams.get('company_id') : companyid;

    const embedPowerBIReport = (report_id, embed_token) => {
        const models = window['powerbi-client'].models;

        const filter = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "Final_Blitz Summary",
                column: "Company ID"
            },
            operator: "In",
            values: [parseInt(company_id)]
        };

        // Embed configuration
        const config = {
            type: 'report',
            id: report_id,
            embedUrl: 'https://app.powerbi.com/reportEmbed',
            accessToken: embed_token,
            tokenType: models.TokenType.Embed,
            permissions: models.Permissions.All,
            filters: [filter],
            settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: true,
            },
        };
    
        // Embed the report
        const embedContainer = document.getElementById('embedContainer');
        const report = window.powerbi.embed(embedContainer, config);
    };

    useEffect(() => {
        setValue({ loading: true });
        // setValue({ breadcrumb_value: "" });
        GetDashboardEmbedData(instance_id, company_id)
        .then((data) => {
            if (data.success === true){
                setValue({ loading: false });
                if (user_role != 'superuser'){
                    // updateBreadcrumb(data['data']['instance_name']);
                    const breadcrumb = {
                        name: data['data']['instance_name'],
                        link: window.location.pathname,
                    };
                    updateBreadcrumb(breadcrumb);
                }
                else{
                    const breadcrumb = {
                        name: 'Preview',
                        link: window.location.pathname,
                    };
                    updateBreadcrumb(breadcrumb);
                }
                setTimeout(() => {
                    embedPowerBIReport(data['data']['report_id'], data['data']['embed_token']);
                }, 3000);
            }
            else{
                setValue({ loading: false });
                setErrorMessage(<Alert variant="danger">{data.message}</Alert>)
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            setValue({ loading: false });
            setErrorMessage(<Alert variant="danger">{error.message}</Alert>)
        })
    }, []);

    return (
        <Layout activeMenuItem={user_role === "superuser" ? "organizations" : "myDashboards"}>
            <div className="dashboard-content">
                {errorMessage}
                <div id="embedContainer" style={{ height: '660px' }}></div>
            </div>
        </Layout>
    );

}