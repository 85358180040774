import React, { useContext, useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col, Alert } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { xhr, Loading } from "../components";
import { Context } from "../providers/mainProvider";
import { useHistory } from "react-router-dom";
import Cookies from "../components/cookies";
import { UpdatePassword } from "../services/profile_services";

const qs = require('qs');

export default function Login() {
  const [updPassErrorMessage, setUpdPassErrorMessage] = useState("");
  const [showUpdPassModal, setShowUpdPassModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [message, setMessage] = useState("");
  const { setValue, loading, user_role } = useContext(Context);
  const history = useHistory();

  const handleCloseUpdPassModal = () => {
    formik_updpass.resetForm();
    setUpdPassErrorMessage("");
    setShowUpdPassModal(false);
  }

  const formik_updpass = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: yup.object({
      new_password: yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirm_password: yup.string()
        .oneOf([yup.ref("new_password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      setValue({ loading: true });
      UpdatePassword(formik.values.password, values.new_password, accessToken)
      .then((data) => {
          if (data.success === true){
              setValue({ loading: false });
              formik.setValues({ ...formik.values, password: values.new_password });
              formik.submitForm();
          }
          else{
            setValue({ loading: false });
            setUpdPassErrorMessage(<Alert variant="danger">{data.message}</Alert>)
          }
      })
      .catch((error) => {
        console.log("Something went wrong");
        console.log("Error: ",error);
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object({
      username: yup
        .string()
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      setValue({ loading: true });
      // values.code = '+98';
      values = qs.stringify(values);
      new xhr({
        url: "/login/",
        data: values
      }).Post((response) => {
        setValue({ loading: false });
        if (response.status) {
          if (response.is_system_admin !== true && response.is_password_updated !== true){
            setShowUpdPassModal(true);
            setAccessToken(response.access_token);
          }
          else{
            var userRole = null
            if (response.is_system_admin === true){
              userRole = 'superuser'
            }
            else if (response.is_company_admin === true){
              userRole = 'manager'
            }
            else{
              userRole = 'user'
            }
            setValue({
              auth: true,
              access_token: response.access_token,
              user_role: userRole,
              companyid: response.company_id ? response.company_id : false
            });
            const user = {
              user_role: userRole,
              companyid: response.company_id ? response.company_id : false
            };
            localStorage.setItem("user", JSON.stringify(user));
            const CookieObj = new Cookies();
            CookieObj.setCookieBatch([
              {
                name: "token",
                value: response.access_token,
                days: 7,
              },
            ]);
            if (response.is_system_admin === true){
              history.push("/company/get/");
            }
            else{
              history.push("/instances/get/");
            }
          }
        } else {
          setMessage(<Alert variant="danger">{response.note}</Alert>);
        }
      });
    },
  });

  // useEffect(() => {
  //   const CookieObj = new Cookies();
  //   let access_token = CookieObj.getCookie("token")

  //   if (access_token) {
  //     if (user_role === 'superuser'){
  //       history.push("/company/get/");
  //     }
  //     else if (user_role === 'manager' || user_role === 'user'){
  //       history.push("/instances/get/");
  //     }
  //     else{
  //       // Do nothing
  //     }
  //   }
  // }, []);

  return (
    <div className="login-page">
      <div className="wrapper">
        <div className="logo">
          <a href="#">
            <img src="/assets/img/logo.png" alt="" />
          </a>
        </div>
        <div className="login-content">
          <div className="title">
            <h2 className="main-title">Login</h2>
            <p>Please fill your details to access your account.</p>
            {message}
          </div>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group controlId="username">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                bsPrefix="form-control ltr"
                // isValid={formik.touched.email && !formik.errors.mobile}
                isInvalid={!!formik.errors.username}
              />
              {/* <Form.Control.Feedback>
                Correct
              </Form.Control.Feedback> */}
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              controlId="password"
              style={{ marginTop: "10px" }}
            >
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                bsPrefix="form-control ltr"
                // isValid={
                //   formik.touched.password && !formik.errors.password
                // }
                isInvalid={!!formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            {/* <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" for="flexCheckDefault">
                Remember me
              </label>
            </div> */}
            <Button
              type="submit"
              className="btn common-btn"
              disabled={loading}
            >
              {loading ? <Loading theme="light" /> : "Login"}
            </Button>
            {/* <div className="row">
              <div className="submit-btn mt-4 d-flex align-items-center jusify-items-between">
                
                <a href="/forgotPassword" className="">
                  Forgot Password?
                </a>
              </div>
            </div> */}
          </Form>
          <Modal
          show={showUpdPassModal}
          onHide={handleCloseUpdPassModal}
          centered
          >
              <Modal.Header closeButton>
                  <Modal.Title>Set a new password</Modal.Title>
              </Modal.Header>
              {updPassErrorMessage}
              <Modal.Body>
                  <Form noValidate onSubmit={formik_updpass.handleSubmit}>
                      <Row>
                          <Col lg={12}>
                              <Form.Group className="form-group">
                                  <Form.Label htmlFor="new_password">Password</Form.Label>
                                  <Form.Control
                                      id="new_password"
                                      type="text"
                                      placeholder="Enter New Password"
                                      name="new_password"
                                      onChange={formik_updpass.handleChange}
                                      onBlur={formik_updpass.handleBlur}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {formik_updpass.errors.new_password}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Col>
                          <Col lg={12}>
                              <Form.Group className="form-group">
                                  <Form.Label htmlFor="confirm_password">Confirm Password</Form.Label>
                                  <Form.Control
                                      id="confirm_password"
                                      type="text"
                                      placeholder="Confirm Password"
                                      name="confirm_password"
                                      onChange={formik_updpass.handleChange}
                                      onBlur={formik_updpass.handleBlur}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {formik_updpass.errors.confirm_password}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Col>
                      </Row>
                      <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="btn-groups">
                              <button type="submit" className="common-btn">
                                  Save
                              </button>
                              <button
                                  className="common-btn white-btn"
                                  onClick={handleCloseUpdPassModal}
                                  >
                              Cancel
                              </button>
                          </div>
                      </div>
                  </Form>
              </Modal.Body>
          </Modal>
          {/* <form action="#">
            <div className="form-group">
              <label for="email-address">Email Address</label>
              <input
                id="email-address"
                className="form-control"
                type="text"
                placeholder="Enter Email Address"
                name="email-address"
                required
              />
            </div>
            <div className="form-group">
              <label for="email-address">Password</label>
              <input
                id="Password"
                className="form-control"
                type="password"
                placeholder="Enter Password"
                name="Password"
                required
              />
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" for="flexCheckDefault">
                Remember me
              </label>
            </div>
            <button type="submit" className="btn common-btn">Login</button>
          </form> */}
        </div>
      </div>
    </div>
  );
}
