import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Form, Row, Col, Nav, Tab, Alert } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useFormik } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { UsersList } from "../services/users";
import { CreateUser } from "../services/create_user";
import { DeleteUser } from "../services/delete_services";
import { DashboardInstances } from "../services/get_dashboard_instances";

export default function UsersPage() {
    const [userList, setUserList] = useState([]);
    const [itemsPerPage] = useState(10);
    const [currentUserPage, setCurrentUserPage] = useState(1);
    const [displayedUserPages, setDisplayedUserPages] = useState(5);
    const [changedData, setChangedData] = useState(null);
    const [instance_options, setInstanceOptions] = useState([]);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [deleteerrorMessage, setDeleteErrorMessage] = useState("");
    const [userErrorMessage, setUserErrorMessage] = useState("");

    const { setValue, companyid, updateBreadcrumb } = useContext(Context);
    const history = useHistory();
    const animatedComponents = makeAnimated();

    const handleCloseAddUserModal = () => {
        formik_user.resetForm();
        setUserErrorMessage("");
        setShowAddUserModal(false);
    }
    const handleShowAddUserModal = () => {
        formik_user.resetForm();
        setShowAddUserModal(true);
    }

    const handleUserClick = (userId) => {
        history.push(`/users/${userId}/get/`);
    };

    const handleUserDelete = (user_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteUser(user_id, companyid)
                        .then((data) => {
                            if (data.success === true) {
                                setShowSuccessPopup(true);
                                setTimeout(() => {
                                    setShowSuccessPopup(false);
                                }, 2000);
                                if (changedData === true){
                                    setChangedData(false);
                                }
                                else{
                                    setChangedData(true);
                                }
                            } else {
                                setValue({ loading: false });
                                setDeleteErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    const formik_user = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            contact_number: "",
            access_level: "manager",
            instances: []
        },
        validationSchema: yup.object({
            firstname: yup
            .string()
            .required('Firstname is required'),
            lastname: yup
            .string()
            .required('Lastname is required'),
            email: yup
            .string()
            .email("Enter Valid Email")
            .required('Email is required'),
            contact_number: yup
            .string()
            .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid Contact Number')
            .required('Contact Number is required')
        }),
        onSubmit: (values) => {
            setValue({ loading: true });
            const instance_value_ids = values.instances.length !== 0 ? values.instances.map(item => item.value) : [];
            const create_values = {
                company_id: Number(companyid),
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                contact_number: values.contact_number,
                is_company_admin: values.access_level === 'manager',
                user_accesses: [
                    {
                        "resource_id": 1,
                        "create_rights": false,
                        "read_rights": true,
                        "update_rights": false,
                        "delete_rights": false
                    },
                    {
                        "resource_id": 2,
                        "create_rights": false,
                        "read_rights": true,
                        "update_rights": false,
                        "delete_rights": false
                    },
                    {
                        "resource_id": 3,
                        "create_rights": false,
                        "read_rights": true,
                        "update_rights": false,
                        "delete_rights": false
                    }
                  ],
                  user_instance_accesses: {
                    "instance_ids": instance_value_ids
                  }
            }
            CreateUser(create_values)
            .then((data) => {
                if (data.success === true){
                    setValue({ loading: false });
                    handleCloseAddUserModal();
                    setShowSuccessPopup(true);
                    setTimeout(() => {
                        setShowSuccessPopup(false);
                    }, 2000);
                    if (changedData === true){
                        setChangedData(false);
                    }
                    else{
                        setChangedData(true);
                    }
                }
                else{
                    setValue({ loading: false });
                    setUserErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        },
    });

    useEffect(() => {
        setValue({ breadcrumb_value: [{name:'Users', link: '/users/get/'}] });
        setValue({ loading: true });
        // updateBreadcrumb('Users');
        // updateBreadcrumb({name:'Users', link: '/users/get/'});
        const fetchData = async () => {
            UsersList(companyid)
            .then((data) => {
                setUserList(data);
                setValue({ loading: false });
            })
            .catch((error) => {
                alert("Something went wrong while fetching Data");
            });
            DashboardInstances(companyid)
            .then((data) => {
                const formattedOptions = data.map((item) => ({
                    value: item.id,
                    label: item.name
                }));
                setInstanceOptions(formattedOptions);
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        }
        fetchData();
        if (changedData) {
            fetchData();
        }
    }, [changedData]);

    const totalUserPages = Math.ceil(userList.length / itemsPerPage);
    const startUserIndex = (currentUserPage - 1) * itemsPerPage;
    const endUserIndex = startUserIndex + itemsPerPage;
    const currentUserData = userList.slice(startUserIndex, endUserIndex);

    const handleUserPageChange = (newPage) => {
        setCurrentUserPage(newPage);

        if (newPage >= displayedUserPages - 1 && newPage <= totalUserPages - displayedUserPages + 2) {
            setDisplayedUserPages((prevPages) => prevPages + (newPage === displayedUserPages - 1 ? 0 : 1));
        }
    };

    const renderUserPagination = () => {
        const userPageNumbers = [];
        for (let i = 1; i <= totalUserPages; i++) {
            userPageNumbers.push(i);
        }

        return (
            <div className="pagination-main">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link prev-btn" onClick={() => {
                        if (currentUserPage > 1) {
                            handleUserPageChange(currentUserPage - 1);
                        }
                    }}></a>
                </li>
                {currentUserPage > Math.floor(displayedUserPages / 2) + 1 && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleUserPageChange(1)}>
                      1
                    </a>
                  </li>
                )}
                {currentUserPage > Math.floor(displayedUserPages / 2) + 2 && <li className="page-item">...</li>}
                {userPageNumbers
                  .filter(
                    (pageNumber) =>
                      pageNumber >= currentUserPage - Math.floor(displayedUserPages / 2) &&
                      pageNumber <= currentUserPage + Math.floor(displayedUserPages / 2)
                  )
                  .map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${pageNumber === currentUserPage ? 'active' : ''}`}
                    >
                      <a className="page-link" onClick={() => handleUserPageChange(pageNumber)}>
                        {pageNumber}
                      </a>
                    </li>
                  ))}
                {currentUserPage < totalUserPages - Math.floor(displayedUserPages / 2) - 1 && <li className="page-item">...</li>}
                {currentUserPage < totalUserPages - Math.floor(displayedUserPages / 2) && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleUserPageChange(totalUserPages)}>
                      {totalUserPages}
                    </a>
                  </li>
                )}
                <li className="page-item">
                    <a className="page-link next-btn" onClick={() => {
                        if (currentUserPage < totalUserPages) {
                            handleUserPageChange(currentUserPage + 1);
                        }
                    }}></a>
                </li>
              </ul>
            </div>
        );
    };

    return (
        <Layout activeMenuItem="users">
            <div className="dashboard-content">
                <div className="responsive main-table">
                    {showSuccessPopup && (
                        <Alert variant="success">Success</Alert>
                    )}
                    {deleteerrorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">Users</h2>
                        <div className="add-user-btn">
                            <button
                                type="button"
                                className="common-btn"
                                onClick={handleShowAddUserModal}
                            >
                                + Add User
                            </button>
                            <Modal
                            show={showAddUserModal}
                            onHide={handleCloseAddUserModal}
                            centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Add User</Modal.Title>
                                </Modal.Header>
                                {userErrorMessage}
                                <Modal.Body>
                                    <Form noValidate onSubmit={formik_user.handleSubmit}>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="firstname">Firstname*</Form.Label>
                                                    <Form.Control
                                                        id="firstname"
                                                        type="text"
                                                        placeholder="Enter First Name"
                                                        name="firstname"
                                                        onChange={formik_user.handleChange}
                                                        onBlur={formik_user.handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik_user.errors.firstname}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="lastname">Lastname*</Form.Label>
                                                    <Form.Control
                                                        id="lastname"
                                                        type="text"
                                                        placeholder="Enter Last Name"
                                                        name="lastname"
                                                        onChange={formik_user.handleChange}
                                                        onBlur={formik_user.handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik_user.errors.lastname}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="email">Email*</Form.Label>
                                                    <Form.Control
                                                        id="email"
                                                        type="text"
                                                        placeholder="Enter Email"
                                                        name="email"
                                                        onChange={formik_user.handleChange}
                                                        onBlur={formik_user.handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik_user.errors.email}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="contact_number">Contact Number*</Form.Label>
                                                    <Form.Control
                                                        id="contact_number"
                                                        type="text"
                                                        placeholder="Enter Contact Number"
                                                        name="contact_number"
                                                        onChange={formik_user.handleChange}
                                                        onBlur={formik_user.handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik_user.errors.contact_number}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Access Level:</Form.Label>
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        <Form.Check
                                                            type="radio"
                                                            id="manager"
                                                            name="access_level"
                                                            value="manager"
                                                            label="Manager"
                                                            onChange={formik_user.handleChange}
                                                            onBlur={formik_user.handleBlur}
                                                            checked={formik_user.values.access_level === 'manager'}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            id="normal_user"
                                                            name="access_level"
                                                            value="normal_user"
                                                            onChange={formik_user.handleChange}
                                                            onBlur={formik_user.handleBlur}
                                                            checked={formik_user.values.access_level === 'normal_user'}
                                                            label="User"
                                                        />
                                                    </div>
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik_user.errors.access_level}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            {formik_user.values.access_level === 'normal_user' && (
                                                <Col lg={12}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="instances">Assign Dashboards</Form.Label>
                                                        <Select
                                                            components={animatedComponents}
                                                            name="instances"
                                                            isMulti
                                                            options={instance_options}
                                                            value={formik_user.values.instances}
                                                            onChange={(selectedOptions) => {
                                                                formik_user.setFieldValue('instances', selectedOptions);
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik_user.errors.instances}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            )}
                                        </Row>
                                        <div className="d-flex align-items-center justify-content-center w-100">
                                            <div className="btn-groups">
                                                <button type="submit" className="common-btn">
                                                    Save
                                                </button>
                                                <button
                                                    className="common-btn white-btn"
                                                    onClick={handleCloseAddUserModal}
                                                    >
                                                Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="col-md-12 table">
                            <thead className="cf">
                                <tr>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Access Level</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUserData.map((user, index) => (
                                <tr
                                    className="clickable_row"
                                    data-company-id={user.company_id}
                                    key={user.id}
                                    onClick={(e) => {
                                        if (!e.target.classList.contains('delete-button')) {
                                            handleUserClick(user.id)
                                        }
                                    }}
                                >
                                    <td data-title="User Name">{user.firstname} {user.lastname}</td>
                                    <td data-title="Email">{user.email}</td>
                                    <td data-title="Contact">{user.contact_number !== null ? user.contact_number : ''}</td>
                                    <td data-title="Access Level">{user.is_company_admin === true ? 'Manager' : 'User'}</td>
                                    <td data-title="Action">
                                        <div className="action-btns">
                                            <button onClick={() => handleUserDelete(user.id)} className="delete-button">
                                                <img src="/assets/img/delete-icon.png" alt="" className="delete-button"/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {renderUserPagination()}
                </div>
            </div>
        </Layout>
    );
}
