import React from "react";
import { ThreeCircles } from "react-loader-spinner";
export default function LoadingSpinner(props) {
    return (
        <>
            <div className={props.loading ? "parentDisable" : ""} width="100%">
                <div className="overlay-box">
                    <ThreeCircles
                        height="100"
                        width="100"
                        color="black"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={props.loading}
                        ariaLabel="three-circles-rotating"
                        outerCircleColor=""
                        innerCircleColor=""
                        middleCircleColor=""
                    />
                </div>
            </div>
        </>
    );
}
