import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Form, Row, Col, Alert } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import { useFormik } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { OrganizationsList } from "../services/organizations";
import { TemplatesList } from "../services/dashboard_templates";
import { CreateDashboardTemplate } from "../services/create_dashboard_template";
import { DeleteDashboardTemplate } from "../services/delete_services";

export default function DashboardTemplates() {
    const [templatesList, setTemplatesList] = useState([]);
    const [itemsPerPage] = useState(10);
    const [currentTemplatesPage, setCurrentTemplatesPage] = useState(1);
    const [displayedTemplatesPages, setDisplayedTemplatesPages] = useState(5);
    const [organizationList, setOrganizationList] = useState([]);
    const [changedData, setChangedData] = useState(null);
    const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [deleteerrorMessage, setDeleteErrorMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { setValue, updateBreadcrumb } = useContext(Context);
    const history = useHistory();
    const animatedComponents = makeAnimated();

    const handleCloseAddTemplateModal = () => {
        formik.resetForm();
        setErrorMessage("");
        setShowAddTemplateModal(false);
    }
    const handleShowAddTemplateModal = () => {
        formik.resetForm();    
        setShowAddTemplateModal(true);
    }

    const handleTemplateClick = (templateId) => {
        history.push(`/templates/${templateId}/get/`);
    };

    const handleTemplateDelete = (template_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteDashboardTemplate(template_id)
                        .then((data) => {
                            if (data.success === true) {
                                setShowSuccessPopup(true);
                                setTimeout(() => {
                                    setShowSuccessPopup(false);
                                }, 2000);
                                if (changedData === true){
                                    setChangedData(false);
                                }
                                else{
                                    setChangedData(true);
                                }
                            } else {
                                setValue({ loading: false });
                                setDeleteErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Something went wrong");
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    const formik = useFormik({
        initialValues: {
            template_name: "",
            workspace_id: "",
            report_id: "",
            dataset_id: "",
            company_ids: []
        },
        validationSchema: yup.object({
            template_name: yup.string().required("Template Name is required"),
            workspace_id: yup.string().required("Workspace Id is required"),
            report_id: yup.string().required("Dashboard Id is required"),
            dataset_id: yup.string().required("Dataset Id is required"),
        }),
        onSubmit: (values) => {
            setValue({ loading: true });
            const company_value_ids = values.company_ids.length !== 0 ? values.company_ids.map(item => item.value) : [];
            CreateDashboardTemplate(
                values.template_name,
                values.workspace_id,
                values.report_id,
                values.dataset_id,
                company_value_ids
            )
            .then((data) => {
                if (data.success === true) {
                    setValue({ loading: false });
                    handleCloseAddTemplateModal();
                    setShowSuccessPopup(true);
                    setTimeout(() => {
                        setShowSuccessPopup(false);
                    }, 2000);
                    if (changedData === true){
                        setChangedData(false);
                    }
                    else{
                        setChangedData(true);
                    }
                } else {
                    setValue({ loading: false });
                    setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                }
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ", error);
            });
        },
    });

    useEffect(() => {
        setValue({ breadcrumb_value: [{name:'Templates', link: '/templates/get/'}] });
        setValue({ loading: true });
        // updateBreadcrumb('Templates');
        // updateBreadcrumb({name:'Templates', link: '/templates/get/'});
        const fetchData = async () => {
            TemplatesList()
                .then((data) => {
                    setTemplatesList(data);
                    setValue({ loading: false });
                })
                .catch((error) => {
                    alert("Something went wrong while fetching Data");
                });
            OrganizationsList()
                .then((data) => {
                    const formattedOptions = data.map((item) => ({
                        value: item.id,
                        label: item.company_name
                    }));
                    setOrganizationList(formattedOptions);
                })
                .catch((error) => {
                    alert("Something went wrong while fetching Data");
                });
        }
        fetchData();
        if (changedData) {
            fetchData();
        }
    }, [changedData]);

    const totalTemplatesPages = Math.ceil(templatesList.length / itemsPerPage);
    const startTemplatesIndex = (currentTemplatesPage - 1) * itemsPerPage;
    const endTemplatesIndex = startTemplatesIndex + itemsPerPage;
    const currentTemplatesData = templatesList.slice(startTemplatesIndex, endTemplatesIndex);

    const handleTemplatesPageChange = (newPage) => {
        setCurrentTemplatesPage(newPage);

        if (newPage >= displayedTemplatesPages - 1 && newPage <= totalTemplatesPages - displayedTemplatesPages + 2) {
            setDisplayedTemplatesPages((prevPages) => prevPages + (newPage === displayedTemplatesPages - 1 ? 0 : 1));
        }
    };

    const renderTemplatesPagination = () => {
        const templatesPageNumbers = [];
        for (let i = 1; i <= totalTemplatesPages; i++) {
            templatesPageNumbers.push(i);
        }

        return (
            <div className="pagination-main">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link prev-btn" onClick={() => {
                        if (currentTemplatesPage > 1) {
                            handleTemplatesPageChange(currentTemplatesPage - 1);
                        }
                    }}></a>
                </li>
                {currentTemplatesPage > Math.floor(displayedTemplatesPages / 2) + 1 && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleTemplatesPageChange(1)}>
                      1
                    </a>
                  </li>
                )}
                {currentTemplatesPage > Math.floor(displayedTemplatesPages / 2) + 2 && <li className="page-item">...</li>}
                {templatesPageNumbers
                  .filter(
                    (pageNumber) =>
                      pageNumber >= currentTemplatesPage - Math.floor(displayedTemplatesPages / 2) &&
                      pageNumber <= currentTemplatesPage + Math.floor(displayedTemplatesPages / 2)
                  )
                  .map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${pageNumber === currentTemplatesPage ? 'active' : ''}`}
                    >
                      <a className="page-link" onClick={() => handleTemplatesPageChange(pageNumber)}>
                        {pageNumber}
                      </a>
                    </li>
                  ))}
                {currentTemplatesPage < totalTemplatesPages - Math.floor(displayedTemplatesPages / 2) - 1 && <li className="page-item">...</li>}
                {currentTemplatesPage < totalTemplatesPages - Math.floor(displayedTemplatesPages / 2) && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleTemplatesPageChange(totalTemplatesPages)}>
                      {totalTemplatesPages}
                    </a>
                  </li>
                )}
                <li className="page-item">
                    <a className="page-link next-btn" onClick={() => {
                        if (currentTemplatesPage < totalTemplatesPages) {
                            handleTemplatesPageChange(currentTemplatesPage + 1);
                        }
                    }}></a>
                </li>
              </ul>
            </div>
        );
    };

    return (
        <Layout activeMenuItem="templates">
            <div className="dashboard-content">
                <div className="responsive main-table">
                    {showSuccessPopup && (
                        <Alert variant="success">Success</Alert>
                    )}
                    {deleteerrorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">Templates</h2>
                        <div className="add-user-btn">
                            <button
                                type="button"
                                className="common-btn"
                                onClick={handleShowAddTemplateModal}
                            >
                                + Add Templates
                            </button>
                            <Modal
                                show={showAddTemplateModal}
                                onHide={handleCloseAddTemplateModal}
                                centered
                            >
                                <Modal.Header closeButton>
                                <Modal.Title>Add Template</Modal.Title>
                                </Modal.Header>
                                {errorMessage}
                                <Modal.Body>
                                <Form noValidate onSubmit={formik.handleSubmit}>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                            <Form.Label htmlFor="template-name">
                                                Name*
                                            </Form.Label>
                                            <Form.Control
                                                id="template-name"
                                                type="text"
                                                placeholder="Enter Template Name"
                                                name="template_name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.template_name}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                            <Form.Label htmlFor="workspace_id">Workspace Id*</Form.Label>
                                            <Form.Control
                                                id="workspace_id"
                                                type="text"
                                                placeholder="Enter Workspace Id"
                                                name="workspace_id"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.workspace_id}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                            <Form.Label htmlFor="report_id">
                                                Dashboard Id*
                                            </Form.Label>
                                            <Form.Control
                                                id="report_id"
                                                type="text"
                                                placeholder="Enter Dashboard Id"
                                                name="report_id"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.report_id}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                            <Form.Label htmlFor="dataset_id">
                                                Dataset Id*
                                            </Form.Label>
                                            <Form.Control
                                                id="dataset_id"
                                                type="text"
                                                placeholder="Enter Dataset Id"
                                                name="dataset_id"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.dataset_id}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="company_ids">Organizations</Form.Label>
                                                <Select
                                                    components={animatedComponents}
                                                    name="company_ids"
                                                    isMulti
                                                    options={organizationList}
                                                    value={formik.values.company_ids}
                                                    onChange={(selectedOptions) => {
                                                        formik.setFieldValue('company_ids', selectedOptions);
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.company_ids}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div className="d-flex align-items-center justify-content-center w-100">
                                    <div className="btn-groups">
                                        <button type="submit" className="common-btn">
                                        Save
                                        </button>
                                        <button
                                        className="common-btn white-btn"
                                        onClick={handleCloseAddTemplateModal}
                                        >
                                        Cancel
                                        </button>
                                    </div>
                                    </div>
                                </Form>
                                </Modal.Body>
                            </Modal>
                            {/* {showSuccessPopup && (
                                <Alert variant="success">Successful!!!</Alert>
                            )} */}
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="col-md-12 table templates_table">
                            <thead className="cf">
                                <tr>
                                <th>Template Name</th>
                                <th>Workspace ID</th>
                                <th>Dashboard ID</th>
                                <th>Dataset ID</th>
                                <th className="numeric"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTemplatesData.map((template, index) => (
                                <tr
                                    className="clickable_row"
                                    key={template.id}
                                    onClick={(e) => {
                                        if (!e.target.classList.contains('delete-button')) {
                                            handleTemplateClick(template.id)
                                        }
                                    }}
                                >
                                    <td data-title="Template Name">{template.name}</td>
                                    <td data-title="Workspace ID">
                                    <span className="long-text">{template.dashboard_workspace_id}</span>
                                    </td>
                                    <td data-title="Dashboard ID">
                                    <span className="long-text">{template.dashboard_report_id}</span>
                                    </td>
                                    <td data-title="Dataset ID">
                                    <span className="long-text"> {template.dashboard_dataset_id}</span>
                                    </td>
                                    <td data-title="Action" className="numeric">
                                        <div className="action-btns">
                                            <button onClick={() => handleTemplateDelete(template.id)} className="delete-button">
                                                <img src="/assets/img/delete-icon.png" alt="" className="delete-button"/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {renderTemplatesPagination()}
                </div>
            </div>
        </Layout>
    );
}
