import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { PrivateRoute } from "./components";

import Login from "./pages/login";
import Organizations from "./pages/organizations_page";
import { Context } from "./providers/mainProvider";
import LoadingSpinner from "./components/spinner";
import ViewOrganization from "./pages/view_organization_page";
import UsersPage from "./pages/users_page";
import ViewUser from "./pages/view_user_page";
import DashboardInstancesPage from "./pages/dashboard_instances_page";
import ViewDashboardInstance from "./pages/view_dashboard_instance_page";
import DisplayDashboardInstance from "./pages/display_dashboard_instance_page";
import DashboardTemplates from "./pages/dashboard_templates_page";
import ViewDashboardTemplate from "./pages/view_dashboard_template_page";
import CostCentresPage from "./pages/costcentre_page";
import ViewMyProfile from "./pages/view_my_profile_page";
import Restricted from "./pages/restricted_message_page";

export default function App() {
    const { loading } = useContext(Context);


  return (
      <>
          <LoadingSpinner loading={loading}></LoadingSpinner>
          <Router>
            <Switch>
                <Route exact path="/" component={Login} />
                {/* <Route
                    exact
                    path="/forgotPassword"
                    component={ForgotPassword}
                /> */}
                <PrivateRoute
                    exact
                    path="/company/get/"
                    component={Organizations}
                    allowedRoles={['superuser']}
                />
                <PrivateRoute
                    exact
                    path="/company/:company_id/get/"
                    component={ViewOrganization}
                    allowedRoles={['superuser']}
                />
                <PrivateRoute
                    exact
                    path="/users/:user_id/get/"
                    component={ViewUser}
                    allowedRoles={['superuser', 'manager']}
                />
                <PrivateRoute
                    exact
                    path="/instances/:instance_id/get/"
                    component={ViewDashboardInstance}
                    allowedRoles={['superuser', 'manager', 'user']}
                />
                <PrivateRoute
                    exact
                    path="/instances/:instance_id/display/"
                    component={DisplayDashboardInstance}
                    allowedRoles={['superuser', 'manager', 'user']}
                />
                <PrivateRoute
                    exact
                    path="/templates/get/"
                    component={DashboardTemplates}
                    allowedRoles={['superuser']}
                />
                <PrivateRoute
                    exact
                    path="/templates/:template_id/get/"
                    component={ViewDashboardTemplate}
                    allowedRoles={['superuser']}
                />
                <PrivateRoute
                    exact
                    path="/users/me/"
                    component={ViewMyProfile}
                    allowedRoles={['superuser', 'manager', 'user']}
                />
                <PrivateRoute
                    exact
                    path="/instances/get/"
                    component={DashboardInstancesPage}
                    allowedRoles={['manager', 'user']}
                />
                <PrivateRoute
                    exact
                    path="/users/get/"
                    component={UsersPage}
                    allowedRoles={['manager']}
                />
                <PrivateRoute
                    exact
                    path="/costcentres/get/"
                    component={CostCentresPage}
                    allowedRoles={['manager']}
                />
                <PrivateRoute path="/restricted" component={Restricted} allowedRoles={['superuser', 'manager', 'user']}/>
            </Switch>
          </Router>
      </>
  );
}
