import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const UpdateOrganization = async (company_id, company_name, address_line_1, address_line_2, address_state, address_pincode, contact_number, salesperson) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.put(
        `company/${company_id}/update/`,
        {
            "company_name": company_name,
            "address_line_1": address_line_1,
            "address_line_2": address_line_2,
            "address_state": address_state,
            "address_pincode": address_pincode,
            "contact_number": contact_number,
            "salesperson": salesperson
        },
        {
            headers:{
                authorization : "Bearer " + access_token ,
                "Content-Type": "application/json",
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}