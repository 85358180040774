import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const TemplateDetails = async (template_id) =>{
    
    
    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var templates_details = [];
    axios.defaults.baseURL = config.servers["base"];
    let urlTOload = `templates/${template_id}/get/`
    
    await axios.get(
        urlTOload,
        {
            headers:{
                authorization : "Bearer " + access_token 
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        templates_details = response.data;
        
        

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            templates_details = [] 
        }
    })
    return await templates_details

}