import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Row, Col, Nav, Tab, Alert } from 'react-bootstrap';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { useFormik } from "formik";
import * as yup from "yup";

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { DashboardInstanceDetails } from "../services/view_dashboard_instance";
import { UpdateDashboardInstance } from "../services/update_dashboard_instance";
import { UploadDashboardInstanceFiles } from "../services/upload_dashboard_instance_files";
import { DashboardTemplates } from "../services/get_dashboard_templates";
import { DeleteDashboardInstance, DeleteDashboardInstanceFile } from "../services/delete_services";
import { RefreshDashboardInstance } from "../services/refresh_dashboard_instance";

export default function ViewDashboardInstance({ instanceName, template }) {

    const [dashboardInstanceDetails, setDashboardInstanceDetails] = useState({});
    const [dashboardInstanceFilesDetails, setDashboardInstanceFilesDetails] = useState([]);
    const [filteredFilesData, setFilteredFilesData] = useState([]);
    const [itemsPerPage] = useState(5);
    const [currentDashboardInstanceFilePage, setCurrentDashboardInstanceFilePage] = useState(1);
    const [displayedDashboardInstanceFilePages, setDisplayedDashboardInstanceFilePages] = useState(5);
    const [changedData, setChangedData] = useState(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showSyncSuccessPopup, setShowSyncSuccessPopup] = useState(false);
    const [showSuccessUploadPopup, setShowSuccessUploadPopup] = useState(false);
    const [selectedYear, setSelectedYear] = useState('');
    const [template_options, setTemplateOptions] = useState([]);
    const [showAddFilesModal, setShowAddFilesModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [ogValues, setInitialValues] = useState({ instanceName, template });
    const [editedInstanceValues, setEditedInstanceValues] = useState({ instanceName, template });
    const [errorMessage, setErrorMessage] = useState("");
    const [fileErrorMessage, setFileErrorMessage] = useState("");
    const [activeTab, setActiveTab] = useState('nav-files');
    const { setValue, user_role, companyid, updateBreadcrumb } = useContext(Context);
    const { instance_id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const company_id = user_role === 'superuser' ? searchParams.get('company_id') : companyid;
    const history = useHistory();

    const [errors, setErrors] = useState({
        instanceName: null,
    });

    const resetAllErrors = () => {
        const updatedErrors = {};
        for (const key in errors) {
            updatedErrors[key] = null;
        }
        setErrors(updatedErrors);
    };

    const monthOptions = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' },
    ];

    const getMonthName = (value) => {
        const month = monthOptions.find(option => option.value === value);
        return month ? month.label : '';
    };

    const handleCloseAddFilesModal = () => {
        formik.resetForm();
        setFileErrorMessage("");
        setShowAddFilesModal(false);
    }
    const handleShowAddFilesModal = () => {
        formik.resetForm();
        setShowAddFilesModal(true);
    }

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    const handleEdit = () => {
        setIsEditing(true);
        setErrorMessage("");
    };

    const handleExitEdit = () => {
        setIsEditing(false);
        setErrorMessage("");
        resetAllErrors();
        setEditedInstanceValues({
            instanceName: ogValues.instanceName,
            template: ogValues.template
        });
    };

    const validationInstanceNameSchema = yup.object().shape({
        instanceName: yup.string().required('Name is required')
    });

    const handleInstanceBlur = async (e) => {
        const { name, value } = e.target;
        try {
            if (name === 'instanceName'){
                await validationInstanceNameSchema.validate({ [name]: value });
            }
            setErrors({ ...errors, [name]: null });
        } catch (err) {
            setErrors({ ...errors, [name]: err.errors[0] });
        }
    };

    const handleSave = (editedInstanceValues, instance_id, company_id) => {
        if (!errors.instanceName){
            setValue({ loading: true });
            UpdateDashboardInstance(instance_id, company_id, editedInstanceValues.instanceName)
            .then((data) => {
                if (data.success === true){
                    setIsEditing(false);
                    setValue({ loading: false });
                    setErrorMessage("");
                    resetAllErrors();
                    setInitialValues(editedInstanceValues);
                }
                else{
                    setValue({ loading: false });
                    setErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                console.error('Error updating dashboard:', error);
                setValue({ loading: false });
                setErrorMessage(<Alert variant="danger">{error.message}</Alert>)
            });
        }
    };

    const handleDashboardDelete = (dashboard_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteDashboardInstance(dashboard_id, company_id)
                        .then((data) => {
                            if (data.success === true) {
                                history.push(`/company/${company_id}/get/`);
                            } else {
                                setValue({ loading: false });
                                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    const handleDashboardInstanceFileDelete = (dashboard_id, file_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        DeleteDashboardInstanceFile(dashboard_id, file_id, company_id)
                        .then((data) => {
                            if (data.success === true) {
                                setShowSuccessPopup(true);
                                setTimeout(() => {
                                    setShowSuccessPopup(false);
                                }, 2000);
                                if (changedData === true){
                                    setChangedData(false);
                                }
                                else{
                                    setChangedData(true);
                                }
                            } else {
                                setValue({ loading: false });
                                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                            }
                        })
                        .catch((error) => {
                            console.log("Error: ", error);
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                    // The user clicked "No," so nothing happens
                    },
                },
            ],
        });
    }

    // const handleDashboardInstanceFileDownload = (fileName, fileUrl) => {
    //     const link = document.createElement('a');
    //     link.href = fileUrl;
    //     link.target = '_new';
    //     link.download = fileName;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // }

    const handleDashboardSync = (instance_id, company_id, template_id) => {
        RefreshDashboardInstance(instance_id, company_id, template_id)
        .then((data) => {
            if (data.success === true) {
                setShowSyncSuccessPopup(true);
                setTimeout(() => {
                    setShowSyncSuccessPopup(false);
                }, 3000);
            }
            else {
                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                setTimeout(() => {
                    setErrorMessage("");
                }, 4000);
            }
        })
    }

    const handlePreview = (instance_id, company_id) => {
        history.push(`/instances/${instance_id}/display/?company_id=${company_id}`);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedInstanceValues({ ...editedInstanceValues, [name]: value });
    };

    const handleYearFilterChange = (event) => {
        const year = parseInt(event.target.value);
        setSelectedYear(year);
        if (!year){
            setFilteredFilesData(dashboardInstanceFilesDetails);
        }
        else{
            const filtered = dashboardInstanceFilesDetails.filter((item) => item.year === parseInt(year));
            setFilteredFilesData(filtered);
        }
    };

    const formik = useFormik({
        initialValues: {
            data_file: null,
            month: '',
            year: '',
        },
        validationSchema: yup.object({
            data_file: yup.mixed()
            .required('Please select a file')
            .test('fileType', 'Invalid file format. Please upload a CSV file.', (value) => {
                if (!value) return false;
                const allowedTypes = ['application/csv', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                return allowedTypes.includes(value.type);
            }),
            month: yup.number().required('Month is required'),
            year: yup.number().required('Year is required')
        }),
        onSubmit: (values) => {
            setValue({ loading: true });
            UploadDashboardInstanceFiles(dashboardInstanceDetails.id, values.data_file, values.month, values.year, company_id)
            .then((data) => {
                if (data.success === true){
                    setValue({ loading: false });
                    handleCloseAddFilesModal();
                    setShowSuccessUploadPopup(true);
                    setTimeout(() => {
                        setShowSuccessUploadPopup(false);
                    }, 5000);
                    if (changedData === true){
                        setChangedData(false);
                    }
                    else{
                        setChangedData(true);
                    }
                }
                else{
                    setValue({ loading: false });
                    setFileErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                setValue({ loading: false });
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        },
    });

    useEffect(() => {
        setValue({ loading: true });
        // setValue({ breadcrumb_value: "" });
        const fetchData = async () => {
            DashboardInstanceDetails(instance_id, company_id)
            .then((data) => {
                setValue({ loading: false });
                setDashboardInstanceDetails(data);
                setDashboardInstanceFilesDetails(data['data_files']);
                setFilteredFilesData(data['data_files']);
                setSelectedYear('');
                setInitialValues({
                    instanceName: data.name,
                    template: data.template_id
                });
                setEditedInstanceValues({
                    instanceName: data.name,
                    template: data.template_id
                });
                // updateBreadcrumb(data['name']);
                const breadcrumb = {
                    name: data['name'],
                    link: window.location.pathname + window.location.search,
                };
                updateBreadcrumb(breadcrumb);
            })
            .catch((error) => {
                setValue({ loading: false });
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
            DashboardTemplates(company_id)
            .then((data) => {
                setTemplateOptions(data); 
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        }
        fetchData();
        if (changedData) {
            fetchData();
        }
    }, [changedData]);

    const totalDashboardInstanceFilePages = Math.ceil(dashboardInstanceFilesDetails.length / itemsPerPage);
    const startDashboardInstanceFileIndex = (currentDashboardInstanceFilePage - 1) * itemsPerPage;
    const endDashboardInstanceFileIndex = startDashboardInstanceFileIndex + itemsPerPage;
    const currentDashboardInstanceFileData = filteredFilesData.slice(startDashboardInstanceFileIndex, endDashboardInstanceFileIndex);

    const handleDashboardInstanceFilePageChange = (newPage) => {
        setCurrentDashboardInstanceFilePage(newPage);

        if (newPage >= displayedDashboardInstanceFilePages - 1 && newPage <= totalDashboardInstanceFilePages - displayedDashboardInstanceFilePages + 2) {
            setDisplayedDashboardInstanceFilePages((prevPages) => prevPages + (newPage === displayedDashboardInstanceFilePages - 1 ? 0 : 1));
        }
    };

    const renderDashboardInstanceFilePagination = () => {
        const dashboardInstanceFilePageNumbers = [];
        for (let i = 1; i <= totalDashboardInstanceFilePages; i++) {
            dashboardInstanceFilePageNumbers.push(i);
        }

        return (
            <div className="pagination-main">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link prev-btn" onClick={() => {
                        if (currentDashboardInstanceFilePage > 1) {
                            handleDashboardInstanceFilePageChange(currentDashboardInstanceFilePage - 1);
                        }
                    }}></a>
                </li>
                {currentDashboardInstanceFilePage > Math.floor(displayedDashboardInstanceFilePages / 2) + 1 && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleDashboardInstanceFilePageChange(1)}>
                      1
                    </a>
                  </li>
                )}
                {currentDashboardInstanceFilePage > Math.floor(displayedDashboardInstanceFilePages / 2) + 2 && <li className="page-item">...</li>}
                {dashboardInstanceFilePageNumbers
                  .filter(
                    (pageNumber) =>
                      pageNumber >= currentDashboardInstanceFilePage - Math.floor(displayedDashboardInstanceFilePages / 2) &&
                      pageNumber <= currentDashboardInstanceFilePage + Math.floor(displayedDashboardInstanceFilePages / 2)
                  )
                  .map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${pageNumber === currentDashboardInstanceFilePage ? 'active' : ''}`}
                    >
                      <a className="page-link" onClick={() => handleDashboardInstanceFilePageChange(pageNumber)}>
                        {pageNumber}
                      </a>
                    </li>
                  ))}
                {currentDashboardInstanceFilePage < totalDashboardInstanceFilePages - Math.floor(displayedDashboardInstanceFilePages / 2) - 1 && <li className="page-item">...</li>}
                {currentDashboardInstanceFilePage < totalDashboardInstanceFilePages - Math.floor(displayedDashboardInstanceFilePages / 2) && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleDashboardInstanceFilePageChange(totalDashboardInstanceFilePages)}>
                      {totalDashboardInstanceFilePages}
                    </a>
                  </li>
                )}
                <li className="page-item">
                    <a className="page-link next-btn" onClick={() => {
                        if (currentDashboardInstanceFilePage < totalDashboardInstanceFilePages) {
                            handleDashboardInstanceFilePageChange(currentDashboardInstanceFilePage + 1);
                        }
                    }}></a>
                </li>
              </ul>
            </div>
        );
    };

    return (
        <Layout activeMenuItem="organizations">
           <div className="dashboard-content">
                <div className="responsive main-table add-organization view-organization">
                    {showSuccessPopup && (
                        <Alert variant="success">Success</Alert>
                    )}
                    {showSuccessUploadPopup && (
                        <Alert variant="success">File is being uploaded. It will appear in the list once uploaded successfully.</Alert>
                    )}
                    {showSyncSuccessPopup && (
                        <Alert variant="success">Refresh initiated. Please check the dashboard after few minutes.</Alert>
                    )}
                    {errorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">{dashboardInstanceDetails['name']}</h2>
                        <div className="view-org-btn-groups">                              
                            {isEditing || dashboardInstanceFilesDetails.length == 0 ? (
                                ''
                            ) : <button className="sync-button" onClick={() => handleDashboardSync(dashboardInstanceDetails['id'], dashboardInstanceDetails['company_id'], dashboardInstanceDetails['template_id'])}><img src="/assets/img/sync-icon.png" alt="" className="sync-button"/></button>}
                            {isEditing || dashboardInstanceFilesDetails.length == 0 ? (
                                ''
                            ) : <button className="common-btn save-btn" onClick={() => handlePreview(dashboardInstanceDetails['id'], dashboardInstanceDetails['company_id'])}>Preview</button>}
                            {isEditing ? (
                                <button className="common-btn save-btn" onClick={() => handleSave(editedInstanceValues, dashboardInstanceDetails['id'], dashboardInstanceDetails['company_id'])}>Save</button>
                            ) : (
                                <button className="common-btn edit-btn" onClick={handleEdit}>
                                    <img src="/assets/img/edit-icon.png" alt="edit" />
                                    <span>Edit</span>
                                </button>
                            )}
                            {isEditing ? (
                                <button className="common-btn white-btn cancel-btn" onClick={handleExitEdit}>Cancel</button>
                            ) : (
                                <button onClick={() => handleDashboardDelete(dashboardInstanceDetails['id'])} type="btn" className="common-btn delete-btn">
                                    <img src="/assets/img/delete-icon.png" alt="edit" /><span
                                        >Delete</span
                                        >
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="add-org-main">
                        <div className="add-dashboard-top">
                            <div className="row">
                                <div className="col-12">
                                    <div className="view-organization-top">
                                        <div className="org-data-info">
                                            <table>
                                                <thead className="cf">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Template</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Dashboard Instance Name">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="instanceName"
                                                                value={editedInstanceValues.instanceName}
                                                                onChange={handleInputChange}
                                                                onBlur={handleInstanceBlur}
                                                                style={{ borderColor: errors.instanceName ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedInstanceValues.instanceName
                                                            )}
                                                            {isEditing && errors.instanceName ? (<div className="validation_error" style={{ color: 'red' }}>{errors.instanceName}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Template">
                                                            {/* {isEditing ? (
                                                                <select
                                                                    id="template"
                                                                    name="template"
                                                                    value={editedInstanceValues.template}
                                                                    onChange={handleInputChange}
                                                                    aria-label="Select Template"
                                                                >
                                                                    <option value="">Select Template</option>
                                                                    {template_options.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                              </select>
                                                            ) : (
                                                                editedInstanceValues.template !== "" ? template_options.find((option) => option.id === editedInstanceValues.template).name : ''
                                                            )} */}
                                                            {/* {editedInstanceValues.template} */}
                                                            {dashboardInstanceDetails.template_id !== "" ? template_options.find((option) => option.id === dashboardInstanceDetails.template_id)?.name : ''}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="add-dashboard-main view-organization-main">
                            <Nav className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                <Nav.Item>
                                    <Nav.Link
                                    className={`nav-link ${activeTab === 'nav-files' ? 'active' : ''}`}
                                    id="nav-files-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-files"
                                    onClick={() => handleTabSelect('nav-files')}
                                    role="tab"
                                    aria-controls="nav-files"
                                    aria-selected={activeTab === 'nav-files'}
                                    >
                                        Files ({dashboardInstanceFilesDetails.length})
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="tab-content" id="nav-add-org">
                                <Tab.Pane className={`tab-pane fade ${activeTab === 'nav-files' ? 'active show' : ''}`}
                                    id="nav-files"
                                    role="tabpanel"
                                    aria-labelledby="nav-files-tab"
                                >
                                    <div className="add-user-btn">
                                        <button type="button" onClick={handleShowAddFilesModal} className="common-btn">
                                            + Add Files
                                        </button>
                                        <Form.Select
                                            name="selectedYear"
                                            value={selectedYear}
                                            onChange={handleYearFilterChange}
                                        >
                                            <option value="">All Years</option>
                                            {Array.from({ length: 10 }, (_, index) => (
                                                <option key={new Date().getFullYear() - index} value={new Date().getFullYear() - index}>
                                                    {new Date().getFullYear() - index}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                    <Modal
                                    show={showAddFilesModal}
                                    onHide={handleCloseAddFilesModal}
                                    centered
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add Files</Modal.Title>
                                        </Modal.Header>
                                        {fileErrorMessage}
                                        <Modal.Body>
                                            <Form noValidate onSubmit={formik.handleSubmit}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="chose-file-box">
                                                            {/* <Form.Group as={Col}> */}
                                                                <Form.Label>Upload File <span>(CSV/Excel Format)</span>*</Form.Label>
                                                                <div className="input-file-box">
                                                                    <Form.Control
                                                                    type="file"
                                                                    id="chosefile"
                                                                    name="data_file"
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue('data_file', e.currentTarget.files[0]);
                                                                        e.currentTarget.style.opacity = 100;
                                                                        e.currentTarget.parentElement.style.height = '38px';
                                                                    }}
                                                                    isInvalid={formik.errors.data_file}
                                                                    />
                                                                </div>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.data_file}
                                                                </Form.Control.Feedback>
                                                            {/* </Form.Group> */}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label htmlFor="month">Month*</Form.Label>
                                                            <Form.Select
                                                                id="month"
                                                                name="month"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                aria-label="Select Month"
                                                            >
                                                                <option value="">
                                                                    Select Month
                                                                </option>
                                                                {monthOptions.map((month) => (
                                                                    <option key={month.value} value={month.value}>
                                                                        {month.label}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.month}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Form.Label htmlFor="year">Year*</Form.Label>
                                                            <Form.Select
                                                                id="year"
                                                                name="year"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                aria-label="Select Year"
                                                            >
                                                                <option value="">
                                                                    Select Year
                                                                </option>
                                                                {Array.from({ length: 10 }, (_, index) => (
                                                                    <option key={new Date().getFullYear() - index} value={new Date().getFullYear() - index}>
                                                                        {new Date().getFullYear() - index}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.year}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex align-items-center justify-content-center w-100">
                                                    <div className="btn-groups">
                                                        <button type="submit" className="common-btn">
                                                            Save
                                                        </button>
                                                        <button
                                                            className="common-btn white-btn"
                                                            onClick={handleCloseAddFilesModal}
                                                            >
                                                        Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Modal.Body>
                                    </Modal>
                                    <div className="table-responsive">
                                        <table className="col-md-12 table">
                                            <thead className="cf">
                                                <tr>
                                                    <th>File Name</th>
                                                    <th>Month</th>
                                                    <th>Year</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentDashboardInstanceFileData.map((file, index) => (
                                                    <tr className="clickable_row" data-file-id={file.id} key={file.id}>
                                                        <td data-title="File Name">{file.filename}</td>
                                                        <td data-title="Month">{getMonthName(file.month)}</td>
                                                        <td data-title="Year">{file.year}</td>
                                                        <td data-title="Action">
                                                            <div className="action-btns">
                                                                {/* <button onClick={() => handleDashboardInstanceFileDownload(file.filename, file.url)} className="edit-button">
                                                                    <img src="/assets/img/edit-icon.png" alt="" className="edit-button"/>
                                                                </button> */}
                                                                <button onClick={() => handleDashboardInstanceFileDelete(dashboardInstanceDetails['id'], file.id)} className="delete-button">
                                                                    <img src="/assets/img/delete-icon.png" alt="" className="delete-button"/>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {renderDashboardInstanceFilePagination()}
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
