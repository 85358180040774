import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert } from 'react-bootstrap';

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { CostCentreList } from "../services/cost_centres";
import { UpdateCostCentre } from "../services/cost_centres";

export default function CostCentresComponent({companyid, items, onTotalCostCentreChange = () => {}}) {
    const [costCentreList, setCostCentreList] = useState([]);
    const [itemsPerPage] = useState(items);
    const [totalCostCentre, setTotalCostCentre] = useState(0);
    const [currentCostCentrePage, setCurrentCostCentrePage] = useState(1);
    const [totalCostCentrePages, setTotalCostCentrePages] = useState(1);
    const [displayedCostCentrePages, setDisplayedCostCentrePages] = useState(5);
    const [editingRowId, setEditingRowId] = useState(null);
    const [costCentreChanges, setCostCentreChanges] = useState({});
    const [changedData, setChangedData] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [serviceNumberSearchTerm, setServiceNumberSearchTerm] = useState('');
    const [usernameSearchTerm, setUsernameSearchTerm] = useState('');

    const { updateBreadcrumb, setValue } = useContext(Context);
    const history = useHistory();


    const handleCostCentreEdit = (rowId) => {
        setCostCentreChanges({});
        setEditingRowId(rowId);
    };

    const handleCostCentreChange = (e, rowId) => {
        const { name, value } = e.target;

        setCostCentreChanges((prevChanges) => ({
            ...prevChanges,
            [rowId]: {
                ...prevChanges[rowId],
                [name]: value,
            },
        }));
    };
    
    const handleSave = (rowId) => {
        setValue({ loading: true });
        const updatedValues = costCentreChanges[rowId];
        const updatedCostCentre = updatedValues.cost_centres !== undefined ? updatedValues.cost_centres : null;
        const updatedComments = updatedValues.comments !== undefined ? updatedValues.comments : null;

        UpdateCostCentre(rowId, updatedCostCentre, updatedComments, companyid)
        .then((data) => {
            if (data.success === true){
                setValue({ loading: false });
                setErrorMessage("");
                setEditingRowId(null);
                setCostCentreChanges({});
                if (changedData === true){
                    setChangedData(false);
                }
                else{
                    setChangedData(true);
                }
            }
            else{
                setValue({ loading: false });
                setErrorMessage(<Alert variant="danger">{data.message}</Alert>)
            }
        })
        .catch((error) => {
            console.error('Error updating costcentre:', error);
            setValue({ loading: false });
            setErrorMessage(<Alert variant="danger">{error.message}</Alert>)
        });
        
    };

    const handleExitEdit = () => {
        setEditingRowId(null);
        setCostCentreChanges({});
    };

    const fetchData = async(current_page) => {
        setValue({ loading: true });
        CostCentreList(companyid, current_page, itemsPerPage, serviceNumberSearchTerm, usernameSearchTerm)
        .then((data) => {
            setCostCentreList(data.data.costcentres);
            setTotalCostCentrePages(Math.ceil(data.data.total_records / itemsPerPage));
            setTotalCostCentre(data.data.total_records);
            onTotalCostCentreChange(data.data.total_records);
            setValue({ loading: false });
        })
        .catch((error) => {
            alert("Something went wrong while fetching Data");
        });
    }

    useEffect(() => {
        // setValue({ breadcrumb_value: [{name:'Cost Centres', link: '/costcentres/get/'}] });
        fetchData(currentCostCentrePage);
        // if (changedData) {
        //     fetchData(currentCostCentrePage);
        // }
    }, [changedData, currentCostCentrePage]);

    const handleSearchOnBlur = () => {
        fetchData(currentCostCentrePage);
    };

    const startCostCentreIndex = (currentCostCentrePage - 1) * itemsPerPage;
    const endCostCentreIndex = startCostCentreIndex + itemsPerPage;

    const handleCostCentrePageChange = (newPage) => {
        setCurrentCostCentrePage(newPage);

        if (newPage >= displayedCostCentrePages - 1 && newPage <= totalCostCentrePages - displayedCostCentrePages + 2) {
            setDisplayedCostCentrePages((prevPages) => prevPages + (newPage === displayedCostCentrePages - 1 ? 0 : 1));
        }
    };

    const renderCostCentrePagination = () => {
        const costCentrePageNumbers = [];
        for (let i = 1; i <= totalCostCentrePages; i++) {
            costCentrePageNumbers.push(i);
        }

        return (
            <div className="pagination-main">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link prev-btn" onClick={() => {
                        if (currentCostCentrePage > 1) {
                            handleCostCentrePageChange(currentCostCentrePage - 1);
                        }
                    }}></a>
                </li>
                {currentCostCentrePage > Math.floor(displayedCostCentrePages / 2) + 1 && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleCostCentrePageChange(1)}>
                      1
                    </a>
                  </li>
                )}
                {currentCostCentrePage > Math.floor(displayedCostCentrePages / 2) + 2 && <li className="page-item">...</li>}
                {costCentrePageNumbers
                  .filter(
                    (pageNumber) =>
                      pageNumber >= currentCostCentrePage - Math.floor(displayedCostCentrePages / 2) &&
                      pageNumber <= currentCostCentrePage + Math.floor(displayedCostCentrePages / 2)
                  )
                  .map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${pageNumber === currentCostCentrePage ? 'active' : ''}`}
                    >
                      <a className="page-link" onClick={() => handleCostCentrePageChange(pageNumber)}>
                        {pageNumber}
                      </a>
                    </li>
                  ))}
                {currentCostCentrePage < totalCostCentrePages - Math.floor(displayedCostCentrePages / 2) - 1 && <li className="page-item">...</li>}
                {currentCostCentrePage < totalCostCentrePages - Math.floor(displayedCostCentrePages / 2) && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleCostCentrePageChange(totalCostCentrePages)}>
                      {totalCostCentrePages}
                    </a>
                  </li>
                )}
                <li className="page-item">
                    <a className="page-link next-btn" onClick={() => {
                        if (currentCostCentrePage < totalCostCentrePages) {
                            handleCostCentrePageChange(currentCostCentrePage + 1);
                        }
                    }}></a>
                </li>
              </ul>
            </div>
        );
    };

    return (
        <div>
            <div className="table-responsive">
                <table className="col-md-12 table">
                    <thead className="cf">
                        <tr>
                            <th>Service Number 
                                <input
                                type="text"
                                style={{ width: '90px' , height: '40px', marginLeft: '10px'}}
                                placeholder="Search..."
                                value={serviceNumberSearchTerm}
                                onChange={(e) => setServiceNumberSearchTerm(e.target.value)}
                                onBlur={handleSearchOnBlur}
                                />
                            </th>
                            <th>Username
                                <input
                                type="text"
                                style={{ width: '90px' , height: '40px', marginLeft: '10px'}}
                                placeholder="Search..."
                                value={usernameSearchTerm}
                                onChange={(e) => setUsernameSearchTerm(e.target.value)}
                                onBlur={handleSearchOnBlur}
                                />
                            </th>
                            <th>Cost Centre</th>
                            <th>Notes</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {costCentreList.map((cost_centre, index) => (
                        <tr
                            className="clickable_row"
                            data-company-id={cost_centre.company_id}
                            key={cost_centre.id}
                        >
                            <td data-title="Service Number">{cost_centre.service_number}</td>
                            <td data-title="Username">{cost_centre.username}</td>
                            <td data-title="Cost Centre">
                                {editingRowId === cost_centre.id ? (
                                    <input
                                    type="text"
                                    name="cost_centres"
                                    value={
                                        costCentreChanges[cost_centre.id]?.cost_centres === "" ?
                                          "" :
                                          costCentreChanges[cost_centre.id]?.cost_centres ||
                                          cost_centre.cost_centre ||
                                          ""
                                      }
                                    onChange={(e) => handleCostCentreChange(e, cost_centre.id)}
                                    />
                                ) : (
                                    cost_centre.cost_centre !== null ? cost_centre.cost_centre : ''
                                )}
                            </td>
                            <td data-title="Notes">
                                {editingRowId === cost_centre.id ? (
                                    <input
                                    type="text"
                                    name="comments"
                                    value={
                                        costCentreChanges[cost_centre.id]?.comments === "" ?
                                          "" :
                                          costCentreChanges[cost_centre.id]?.comments ||
                                          cost_centre.comments ||
                                          ""
                                      }
                                    onChange={(e) => handleCostCentreChange(e, cost_centre.id)}
                                    />
                                ) : (
                                    cost_centre.comments !== null ? cost_centre.comments : ''
                                )}
                            </td>
                            <td data-title="Action">
                                <div className="action-btns">
                                    {editingRowId === cost_centre.id ? (
                                        <>
                                            <button onClick={() => handleSave(cost_centre.id)} className="save-button"><img src="/assets/img/save-icon.png" alt="" className="save-button"/></button>
                                            <button onClick={handleExitEdit} className="cancel-button"><img src="/assets/img/cancel-icon.png" alt="" className="cancel-button"/></button>
                                        </>
                                    ) : (
                                        <button onClick={() => handleCostCentreEdit(cost_centre.id)} className="edit-button">
                                            <img src="/assets/img/edit-icon.png" alt="" className="edit-button"/>
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {renderCostCentrePagination()}
        </div>
    );
}
