import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const UpdateUser = async (user_id, firstname, lastname, email, contact_number, is_company_admin, instance_ids, company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.put(
        `users/${user_id}/update/`,
        {
            "company_id": company_id,
            "firstname": firstname,
            "lastname": lastname,
            "email": email,
            "contact_number": contact_number,
            "is_company_admin": is_company_admin,
            "user_instance_accesses": {
                "instance_ids": instance_ids
            }
        },
        {
            headers:{
                authorization : "Bearer " + access_token ,
                "Content-Type": "application/json",
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}