import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const GetDashboardEmbedData = async (instance_id, company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.post(
        `instances/${instance_id}/display/`,
        {
            "company_id": company_id
        },
        {
            headers:{
                authorization : "Bearer " + access_token ,
                "Content-Type": "application/json",
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}