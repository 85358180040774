import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../providers/mainProvider";

export default function Breadcrumb(props) {
  const { breadcrumb_value } = useContext(Context);

  return (
    <ul>
      {/* <li>
        <a href="/company/get/">
          <label className="active" style={{ cursor: "pointer" }}>
            Organizations
          </label>
        </a>
      </li> */}
      {breadcrumb_value.map((breadcrumb, index) => (
        <li key={index}>
          {index > 0 && (
            <>
              <img src="/assets/img/right-arrow.png" alt="" />
            </>
          )}
          {index === breadcrumb_value.length - 1 ? (
            <label className="active">{breadcrumb.name}</label>
          ) : (
            <Link to={breadcrumb.link}>{breadcrumb.name}</Link>
          )}
        </li>
      ))}
      {/* <li>
          <img src="/assets/img/right-arrow.png" alt=""></img>
          <label>{props.currentPage}</label>
      </li> */}
    </ul>
  );
}
