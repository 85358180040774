import React, { useContext, useEffect, useState } from "react";
import { Context } from "../providers/mainProvider";
import { useHistory } from "react-router-dom";
import { Nav } from "react-bootstrap";

export default function SideBar({ activeMenuItem }) {
  const { setValue, sidebar_hide, user_role } = useContext(Context);
  const history = useHistory();
  // const {assistant_name, assistant_token} = useContext(Context);
  const [menuItems, setMenuItems] = useState([]);

  const allowedMenuItems = {
    superuser: ['organizations', 'templates'],
    manager: ['myDashboards', 'users', 'costcentres'],
    user: ['myDashboards'],
  };

  const getDashboardLink = (userRole) => {
    switch (userRole) {
      case 'superuser':
        return '/company/get/';
      case 'manager':
        return '/instances/get/';
      case 'user':
        return '/instances/get/';
      default:
        return '/'; // Default link, can be the login page or another landing page
    }
  }

  // Function to get the menu item link based on the menu item key
  const getMenuItemLink = (menuItem) => {
    switch (menuItem) {
      case 'organizations':
        return 'company/get/';
      case 'templates':
        return 'templates/get/';
      case 'myDashboards':
        return 'instances/get/';
      case 'users':
        return 'users/get/';
      case 'costcentres':
        return 'costcentres/get/';
      default:
        return '/'; // Default link, can be the login page or another landing page
    }
  }

  // Function to get the menu item icon based on the menu item key
  const getMenuItemIcon = (menuItem) => {
    switch (menuItem) {
      case 'organizations':
        return 'organization.png';
      case 'templates':
        return 'dashboard.png';
      case 'myDashboards':
        return 'dashboard.png'; // to be changed
      case 'users':
        return 'groups.png'; // to be changed
      case 'costcentres':
        return 'costcentre.png'
      default:
        return 'default.png'; // Default icon image to be changed
    }
  }

  // Function to get the menu item text based on the menu item key
  const getMenuItemText = (menuItem) => {
    switch (menuItem) {
      case 'organizations':
        return 'Organizations';
      case 'templates':
        return 'Templates';
      case 'myDashboards':
        return 'My Dashboards';
      case 'users':
        return 'Users';
      case 'costcentres':
        return 'Cost Centres';
      default:
        return ''; // Default text for unknown menu items
    }
  }

  // function navigate(url) {
  //   setValue({
  //     sidebar_hide: false,
  //   });
  //   history.push(url);
  // }
  // var classToShow = "";
  // if (sidebar_hide) {
  //   classToShow = "close";
  // }

  // function showHelp() {
  //   onclickClose();
  //   document.querySelector(".chat-button").click();
  // }

  // function onclickClose() {
  //   setValue({
  //     sidebar_hide: false,
  //   });
  // }
  // useEffect(() => {
  //   if (assistant_name === "") {
  //     const tempMenuItems = [
  //       {
  //         active_img_path: "/assets/img/ic-dashboard-white.png",
  //         img_path: "/assets/img/ic-dashboard-grey.png",
  //         link: "/dashboard",
  //         name: "Dashboard",
  //       },
  //       {
  //         active_img_path: "/assets/img/ic-assistants-white.png",
  //         img_path: "/assets/img/ic-assistants-grey.png",
  //         link: "/assistants",
  //         name: "Assistants",
  //       },
  //       {
  //         active_img_path: "/assets/img/ic-account-white.png",
  //         img_path: "/assets/img/ic-account-grey.png",
  //         link: "/account",
  //         name: "Account",
  //       },
  //     ];
  //     setMenuItems(tempMenuItems);
  //   } else {
  //     const tempMenuItems = [
  //       {
  //         active_img_path: "/assets/img/ic-assistants-white.png",
  //         img_path: "/assets/img/ic-assistants-grey.png",
  //         name: "Assistants",
  //         link: "/assistants",
  //       },
  //       {
  //         active_img_path: "/assets/img/ic-dashboard-white.png",
  //         img_path: "/assets/img/ic-dashboard-grey.png",
  //         name: "Assistant Statistics",
  //         link: "/assistant-dashboard/",
  //       },
  //       {
  //         active_img_path: "/assets/img/ic-mange-data-white.png",
  //         img_path: "/assets/img/ic-mange-data-grey.png",
  //         name: "Manage Data Sources",
  //         link: "/datasources/" + assistant_token,
  //       },
  //       {
  //         active_img_path: "/assets/img/ic-playground-white.png",
  //         img_path: "/assets/img/ic-playground-grey.png",
  //         name: "Playground",
  //         link: "/playground/" + assistant_token,
  //       },
  //       {
  //         active_img_path: "/assets/img/ic-settings-white.png",
  //         img_path: "/assets/img/ic-settings-grey.png",
  //         name: "Settings",
  //         link: "/settings/" + assistant_token,
  //       },
  //       {
  //         active_img_path: "/assets/img/ic-api-key-white.png",
  //         img_path: "/assets/img/ic-api-key-grey.png",
  //         name: "API Key",
  //         link: "/api-key/" + assistant_token,
  //       },
  //       {
  //         active_img_path: "/assets/img/ic-export-white.png",
  //         img_path: "/assets/img/ic-export-grey.png",
  //         name: "Embed/Export",
  //         link: "/embed/" + assistant_token,
  //       },
  //     ];
  //     setMenuItems(tempMenuItems);
  //   }
  // }, [assistant_name, assistant_token]);

  return (
    <nav className="sidebar">
      <div className="logo">
        <a href={getDashboardLink(user_role)}>
          <img src="/assets/img/logo-icon.png" alt="" />
          <span>
            iRevue <small>Powered by Aumento </small>
          </span>
        </a>
      </div>
      <div className="menu-items">
        {allowedMenuItems[user_role] && (
          <ul className="nav-links">
            {allowedMenuItems[user_role].map((menuItem) => (
              <li key={menuItem} className={activeMenuItem === menuItem ? 'active' : ''}>
                <a href={`/${getMenuItemLink(menuItem)}`}>
                  <div className="icon">
                    <img src={`/assets/img/${getMenuItemIcon(menuItem)}`} alt="" />
                  </div>
                  <span>{getMenuItemText(menuItem)}</span>
                </a>
              </li>
            ))}
            {/* <li className={activeMenuItem === 'organizations' ? 'active' : ''}>
              <a href="/company/get/">
                <div className="icon">
                  <img src="/assets/img/organization.png" alt="" />
                </div>
                <span>Organizations</span>
              </a>
            </li>
            <li>
              <a href="#">
                <div className="icon"><img src="/assets/img/groups.png" alt="" /></div>
                <span>Groups</span>
              </a>
            </li>
            <li className={activeMenuItem === 'templates' ? 'active' : ''}>
              <a href="/templates/get/">
                <div className="icon">
                  <img src="/assets/img/dashboard.png" alt="" />
                </div>
                <span>Templates</span>
              </a>
            </li> */}
          </ul>
        )}
      </div>
    </nav>
    // <div
    //   className={classToShow}
    //   tabIndex="-1"
    //   id="offcanvas"
    //   data-bs-keyboard="false"
    //   data-bs-backdrop="false"
    // >
    //   <div className="offcanvas-header">
    //     <button
    //       type="button"
    //       className="btn-close text-reset"
    //       data-bs-dismiss="offcanvas"
    //       aria-label="Close"
    //       onClick={onclickClose}
    //     ></button>
    //   </div>
    //   <div className="offcanvas-body px-0">
    //     <div className="sidebar_mobile_sidebar">
    //       <div className="logo text-center p-3" style={{ cursor: "pointer" }}>
    //         <a href="/assistants">
    //           <img src="/assets/img/logo.png" alt="" />
    //         </a>
    //       </div>
    //       <div className="sidebar-menu-list">
    //         {menuItems.map(function (item, index) {
    //           var classString = "menu-items";
    //           var image_path = item.img_path;
    //           if (window.location.pathname.includes(item.link)) {
    //             classString = "menu-items active";
    //             image_path = item.active_img_path;
    //           }
    //           return (
    //             <div
    //               className={classString}
    //               onClick={function t() {
    //                 navigate(item.link);
    //               }}
    //             >
    //               <div className="icon">
    //                 <img src={image_path} alt=""></img>
    //               </div>
    //               <div className="title">
    //                 <p>{item.name}</p>
    //               </div>
    //             </div>
    //           );
    //         })}
    //         <div className="menu-items">
    //           <ul>
    //             <li>
    //               <div className="version">
    //                 <p>Alpha v0.1</p>
    //               </div>
    //             </li>
    //             <li>
    //               <div
    //                 className="nav_help"
    //                 onClick={showHelp}
    //                 style={{ cursor: "pointer" }}
    //               >
    //                 <div className="icon">
    //                   <img src="/assets/img/ic-help-blue.png" alt="" />
    //                 </div>
    //                 <div className="title">
    //                   <p>Help</p>
    //                 </div>
    //               </div>
    //             </li>
    //             <li>
    //               <div className="nav_help">
    //                 <div className="icon">
    //                   <img src="/assets/img/ic-feedback-blue.png" alt="" />
    //                 </div>
    //                 <div className="title">
    //                   <a
    //                     href="https://forms.office.com/r/h8fTM3VNy1"
    //                     target="_blank"
    //                   >
    //                     Give Feedback
    //                   </a>
    //                 </div>
    //               </div>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
