import React from "react";
import Layout from "../layout/layout";

export default function Restricted() {
    return (
        <Layout activeMenuItem="">
            <div className="dashboard-content">
                <div className="responsive main-table">
                    <div>Access Denied: You do not have permission to access this page.</div>
                </div>
            </div>
        </Layout>
    );
}