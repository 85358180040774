import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Row, Col, Alert } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { ProfileDetails, UpdateProfile, UpdatePassword } from "../services/profile_services";

export default function ViewMyProfile({ firstname, lastname, email, contact_number }) {

    const [userDetails, setUserDetails] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [ogValues, setInitialValues] = useState({ firstname, lastname, email, contact_number });
    const [editedUserValues, setEditedUserValues] = useState({ firstname, lastname, email, contact_number });
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [updPassErrorMessage, setUpdPassErrorMessage] = useState("");
    const [showUpdPassModal, setShowUpdPassModal] = useState(false);
    const { setValue } = useContext(Context);
    const { updateBreadcrumb } = useContext(Context);
    const { logout } = useContext(Context);
    const history = useHistory();

    const [errors, setErrors] = useState({
        firstname: null,
        lastname: null,
        contact_number: null,
    });

    const resetAllErrors = () => {
        const updatedErrors = {};
        for (const key in errors) {
            updatedErrors[key] = null;
        }
        setErrors(updatedErrors);
    };

    const handleCloseUpdPassModal = () => {
        formik.resetForm();
        setUpdPassErrorMessage("");
        setShowUpdPassModal(false);
    }
    const handleShowUpdPassModal = () => setShowUpdPassModal(true);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleExitEdit = () => {
        setIsEditing(false);
        resetAllErrors();
        setEditedUserValues({
            firstname: ogValues.firstname,
            lastname: ogValues.lastname,
            email: ogValues.email,
            contact_number: ogValues.contact_number
        });
    };

    const validationFnameSchema = yup.object().shape({
        firstname: yup.string().required('Firstname is required')
    });

    const validationLnameSchema = yup.object().shape({
        lastname: yup.string().required('Lastname is required')
    });

    const validationContactSchema = yup.object().shape({
        contact_number: yup
        .string()
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid Contact Number')
        .required('Contact Number is required')
    })

    const handleProfileBlur = async (e) => {
        const { name, value } = e.target;
        try {
            if (name === 'firstname'){
                await validationFnameSchema.validate({ [name]: value });
            }
            if (name === 'lastname'){
                await validationLnameSchema.validate({ [name]: value });
            }
            if (name === 'contact_number'){
                await validationContactSchema.validate({ [name]: value });
            }
            setErrors({ ...errors, [name]: null });
        } catch (err) {
            setErrors({ ...errors, [name]: err.errors[0] });
        }
    };

    const handleSave = (editedUserValues) => {
        if (!errors.firstname && !errors.lastname && !errors.contact_number){
            setValue({ loading: true });
            UpdateProfile(editedUserValues.firstname, editedUserValues.lastname, editedUserValues.contact_number)
            .then((data) => {
                if (data.success === true){
                    setIsEditing(false);
                    setValue({ loading: false });
                    resetAllErrors();
                    setInitialValues(editedUserValues);
                }
                else{
                    setValue({ loading: false });
                    setErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                console.error('Error updating user:', error);
                setValue({ loading: false });
                setErrorMessage(<Alert variant="danger">{error.message}</Alert>)
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUserValues({ ...editedUserValues, [name]: value });
    };

    const formik = useFormik({
        initialValues: {
            current_password: "",
            new_password: "",
            confirm_password: ""
        },
        validationSchema: yup.object({
            current_password: yup
            .string()
            .required('Current Password is required'),
            new_password: yup
            .string()
            .required('New Password is required'),
            confirm_password: yup.string()
            .oneOf([yup.ref("new_password"), null], "Passwords must match")
            .required("Confirm Password is required"),
        }),
        onSubmit: (values) => {
            setValue({ loading: true });
            UpdatePassword(values.current_password, values.new_password)
            .then((data) => {
                if (data.success === true){
                    setValue({ loading: false });
                    handleCloseUpdPassModal();
                    setShowSuccessPopup(true);
                    setTimeout(() => {
                        setShowSuccessPopup(false);
                        logout();
                    }, 3000);
                }
                else{
                    setValue({ loading: false });
                    setUpdPassErrorMessage(<Alert variant="danger">{data.message}</Alert>)
                }
            })
            .catch((error) => {
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
        },
    });

    useEffect(() => {
        setValue({ loading: true });
        setValue({ breadcrumb_value: "" });
        ProfileDetails()
            .then((data) => {
                setUserDetails(data);
                setValue({ loading: false });
                setInitialValues({
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    contact_number: data.contact_number
                });
                setEditedUserValues({
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    contact_number: data.contact_number
                });
                // setValue({ breadcrumb_value: data['firstname'] + ' ' + data['lastname'] });
                // updateBreadcrumb('My Profile');
            })
            .catch((error) => {
                setValue({ loading: false });
                console.log("Something went wrong");
                console.log("Error: ",error);
            });
    }, []);

    return (
        <Layout activeMenuItem="">
           <div className="dashboard-content">
                <div className="responsive main-table add-organization view-organization">
                    {showSuccessPopup && (
                        <Alert variant="success">Password Update Successful, Please Login Again.</Alert>
                    )}
                    {errorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">My Profile</h2>
                        <div className="view-org-btn-groups">
                            {isEditing ? (
                                ''
                            ) : <button className="common-btn save-btn" onClick={handleShowUpdPassModal}>Update Password</button>}
                            {isEditing ? (
                                <button className="common-btn save-btn" onClick={() => handleSave(editedUserValues)}>Save</button>
                            ) : (
                                <button className="common-btn edit-btn" onClick={handleEdit}>
                                    <img src="/assets/img/edit-icon.png" alt="edit" />
                                    <span>Edit</span>
                                </button>
                            )}
                            {isEditing ? (
                                <button className="common-btn white-btn cancel-btn" onClick={handleExitEdit}>Cancel</button>
                            ) : ''}
                        </div>
                    </div>
                    <Modal
                    show={showUpdPassModal}
                    onHide={handleCloseUpdPassModal}
                    centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Update Password</Modal.Title>
                        </Modal.Header>
                        {updPassErrorMessage}
                        <Modal.Body>
                            <Form noValidate onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col lg={12}>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="current_password">Current Password</Form.Label>
                                            <Form.Control
                                                id="current_password"
                                                type="text"
                                                placeholder="Enter Current Password"
                                                name="current_password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.current_password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="new_password">New Password</Form.Label>
                                            <Form.Control
                                                id="new_password"
                                                type="text"
                                                placeholder="Enter New Password"
                                                name="new_password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.new_password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="confirm_password">Confirm Password</Form.Label>
                                            <Form.Control
                                                id="confirm_password"
                                                type="text"
                                                placeholder="Confirm Password"
                                                name="confirm_password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.confirm_password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center justify-content-center w-100">
                                    <div className="btn-groups">
                                        <button type="submit" className="common-btn">
                                            Save
                                        </button>
                                        <button
                                            className="common-btn white-btn"
                                            onClick={handleCloseUpdPassModal}
                                            >
                                        Cancel
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    <div className="add-org-main">
                        <div className="add-dashboard-top">
                            <div className="row">
                                <div className="col-12">
                                    <div className="view-organization-top">
                                        <div className="org-data-info">
                                            <table>
                                                <thead className="cf">
                                                    <tr>
                                                        <th>Firstname</th>
                                                        <th>Lastname</th>
                                                        <th>Contact</th>
                                                        <th>Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-title="Firstname">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="firstname"
                                                                value={editedUserValues.firstname}
                                                                onChange={handleInputChange}
                                                                onBlur={handleProfileBlur}
                                                                style={{ borderColor: errors.firstname ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedUserValues.firstname
                                                            )}
                                                            {isEditing && errors.firstname ? (<div className="validation_error" style={{ color: 'red' }}>{errors.firstname}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Lastname">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="lastname"
                                                                value={editedUserValues.lastname}
                                                                onChange={handleInputChange}
                                                                onBlur={handleProfileBlur}
                                                                style={{ borderColor: errors.lastname ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedUserValues.lastname
                                                            )}
                                                            {isEditing && errors.lastname ? (<div className="validation_error" style={{ color: 'red' }}>{errors.lastname}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Contact Number">
                                                            {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="contact_number"
                                                                value={editedUserValues.contact_number}
                                                                onChange={handleInputChange}
                                                                onBlur={handleProfileBlur}
                                                                style={{ borderColor: errors.contact_number ? 'red' : '' }}
                                                                />
                                                            ) : (
                                                                editedUserValues.contact_number
                                                            )}
                                                            {isEditing && errors.contact_number ? (<div className="validation_error" style={{ color: 'red' }}>{errors.contact_number}</div>) : ('')}
                                                        </td>
                                                        <td data-title="Email">
                                                            {editedUserValues.email}
                                                            {/* {isEditing ? (
                                                                <input
                                                                type="text"
                                                                name="email"
                                                                value={editedUserValues.email}
                                                                onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                editedUserValues.email
                                                            )} */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}