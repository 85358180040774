import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const UserDetails = async (user_id, company_id) =>{
    
    
    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var user_details = {};
    axios.defaults.baseURL = config.servers["base"];
    let urlTOload = `/users/${user_id}/get/`
    
    await axios.post(
        urlTOload,
        {
            "company_id": company_id
        },
        {
            headers:{
                authorization : "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        user_details = response.data;
        
        

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            user_details = {}
        }
    })
    return await user_details

}