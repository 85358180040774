import React, { useContext, Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { Context } from "../providers/mainProvider";

export default class PrivateRoute extends Component {
    static contextType = Context;

    componentDidMount() {
        const { setValue } = this.context;
        const storedUser = localStorage.getItem("user");

        if (storedUser) {
            const user = JSON.parse(storedUser);
            setValue({
                user_role: user.user_role,
                companyid: user.companyid ? user.companyid : false
            });
        }
    }

    render() {
        const { component: Component, allowedRoles, ...restProps } = this.props;
        let { auth, user_role } = this.context;
        return (
            <Route
                {...restProps}
                render={(props) =>{
                    if (auth) {
                        if (allowedRoles.includes(user_role)) {
                            return <Component {...props.changeBatch(restProps)} />
                        }
                        else{
                            return (<Redirect to={{
                                pathname: "/restricted",
                                state: { from: props.location },
                            }}
                        />)
                        }
                    } 
                    else {
                        return (<Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location },
                            }}
                        />)
                    }
                }
                }
            />
        );
    }
}
