import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Form, Row, Col, Alert } from "react-bootstrap";

import { Context } from "../providers/mainProvider";
import Layout from "../layout/layout";
import { DashboardInstances } from "../services/get_dashboard_instances";
import { RefreshDashboardInstance } from "../services/refresh_dashboard_instance";

export default function DashboardInstancesPage() {
    const [instanceList, setInstanceList] = useState([]);
    const [itemsPerPage] = useState(10);
    const [currentInstancePage, setCurrentInstancePage] = useState(1);
    const [displayedInstancePages, setDisplayedInstancePages] = useState(5);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showSyncSuccessPopup, setShowSyncSuccessPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { setValue, user_role, companyid, updateBreadcrumb } = useContext(Context);
    const history = useHistory();

    const handleInstanceClick = (instanceId) => {
        history.push(`/instances/${instanceId}/display/`);
    };

    const handleDashboardSync = (instance_id, company_id, template_id) => {
        RefreshDashboardInstance(instance_id, company_id, template_id)
        .then((data) => {
            if (data.success === true) {
                setShowSyncSuccessPopup(true);
                setTimeout(() => {
                    setShowSyncSuccessPopup(false);
                }, 3000);
            }
            else {
                setErrorMessage(<Alert variant="danger">{data.message}</Alert>);
                setTimeout(() => {
                    setErrorMessage("");
                }, 4000);
            }
        })
    }

    // const handleInstanceEdit = (instnaceId) => {
    //     history.push(`/instances/${instanceId}/get/`);
    // }

    useEffect(() => {
        setValue({ breadcrumb_value: [{name:'My Dashboards', link: '/instances/get/'}] });
        setValue({ loading: true });
        // updateBreadcrumb('My Dashboards');
        // updateBreadcrumb({name:'My Dashboards', link: '/instances/get/'});
        const fetchData = async () => {
            DashboardInstances(companyid)
                .then((data) => {
                    const filteredInstanceData = data.filter((item) => item.data_files.length > 0);
                    setInstanceList(filteredInstanceData);
                    setValue({ loading: false });
                })
                .catch((error) => {
                    alert("Something went wrong while fetching Data");
                });
        }
        fetchData();
    }, []);

    const totalInstancePages = Math.ceil(instanceList.length / itemsPerPage);
    const startInstanceIndex = (currentInstancePage - 1) * itemsPerPage;
    const endInstanceIndex = startInstanceIndex + itemsPerPage;
    const currentInstanceData = instanceList.slice(startInstanceIndex, endInstanceIndex);

    const handleInstancePageChange = (newPage) => {
        setCurrentInstancePage(newPage);

        if (newPage >= displayedInstancePages - 1 && newPage <= totalInstancePages - displayedInstancePages + 2) {
            setDisplayedInstancePages((prevPages) => prevPages + (newPage === displayedInstancePages - 1 ? 0 : 1));
        }
    };

    const renderInstancePagination = () => {
        const instancePageNumbers = [];
        for (let i = 1; i <= totalInstancePages; i++) {
            instancePageNumbers.push(i);
        }

        return (
            <div className="pagination-main">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link prev-btn" onClick={() => {
                        if (currentInstancePage > 1) {
                            handleInstancePageChange(currentInstancePage - 1);
                        }
                    }}></a>
                </li>
                {currentInstancePage > Math.floor(displayedInstancePages / 2) + 1 && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleInstancePageChange(1)}>
                      1
                    </a>
                  </li>
                )}
                {currentInstancePage > Math.floor(displayedInstancePages / 2) + 2 && <li className="page-item">...</li>}
                {instancePageNumbers
                  .filter(
                    (pageNumber) =>
                      pageNumber >= currentInstancePage - Math.floor(displayedInstancePages / 2) &&
                      pageNumber <= currentInstancePage + Math.floor(displayedInstancePages / 2)
                  )
                  .map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${pageNumber === currentInstancePage ? 'active' : ''}`}
                    >
                      <a className="page-link" onClick={() => handleInstancePageChange(pageNumber)}>
                        {pageNumber}
                      </a>
                    </li>
                  ))}
                {currentInstancePage < totalInstancePages - Math.floor(displayedInstancePages / 2) - 1 && <li className="page-item">...</li>}
                {currentInstancePage < totalInstancePages - Math.floor(displayedInstancePages / 2) && (
                  <li className="page-item">
                    <a className="page-link" onClick={() => handleInstancePageChange(totalInstancePages)}>
                      {totalInstancePages}
                    </a>
                  </li>
                )}
                <li className="page-item">
                    <a className="page-link next-btn" onClick={() => {
                        if (currentInstancePage < totalInstancePages) {
                            handleInstancePageChange(currentInstancePage + 1);
                        }
                    }}></a>
                </li>
              </ul>
            </div>
        );
    };

    return (
        <Layout activeMenuItem="myDashboards">
            <div className="dashboard-content">
                <div className="responsive main-table">
                    {showSuccessPopup && (
                        <Alert variant="success">Refresh Successful</Alert>
                    )}
                    {showSyncSuccessPopup && (
                        <Alert variant="success">Refresh initiated. Please check the dashboard after few minutes.</Alert>
                    )}
                    {errorMessage}
                    <div className="dashboard-title-section">
                        <h2 className="main-title">My Dashboards</h2>
                    </div>
                    <div className="table-responsive">
                        <table className="col-md-12 table">
                            <thead className="cf">
                                <tr>
                                    <th>Dashboard Name</th>
                                    <th></th>
                                    <th></th>
                                    {/* <th className="numeric"></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {currentInstanceData.map((instance, index) => (
                                <tr
                                    className="clickable_row"
                                    data-company-id={instance.company_id}
                                    key={instance.id}
                                    onClick={(e) => {
                                        if (!e.target.classList.contains('sync-button')) {
                                            handleInstanceClick(instance.id)
                                        }
                                    }}
                                >
                                    <td data-title="Name">{instance.name}</td>
                                    <td></td>
                                    {user_role === 'manager' && (
                                        <td data-title="Action" className="numeric">
                                            <div className="action-btns">
                                                <button onClick={() => handleDashboardSync(instance.id, instance.company_id, instance.template_id)} className="sync-button">
                                                    <img src="/assets/img/sync-icon.png" alt="" className="sync-button"/>
                                                </button>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {renderInstancePagination()}
                </div>
            </div>
        </Layout>
    );
}
