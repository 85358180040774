import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const ProfileDetails = async () =>{
    
    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var user_details = {};
    axios.defaults.baseURL = config.servers["base"];
    let urlTOload = `/users/me/`
    
    await axios.post(
        urlTOload,
        {},
        {
            headers:{
                authorization : "Bearer " + access_token,
                "Content-Type": "application/json"
            },
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        user_details = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            user_details = {}
        }
    })
    return await user_details

}

export const UpdateProfile = async (firstname, lastname, contact_number) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.put(
        `users/me/update/`,
        {
            "firstname": firstname,
            "lastname": lastname,
            "contact_number": contact_number,
        },
        {
            headers:{
                authorization : "Bearer " + access_token ,
                "Content-Type": "application/json",
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}

export const UpdatePassword = async (current_password, new_password, token=null) =>{

    const CookieObj = new Cookies();
    let access_token = token !== null ? token : CookieObj.getCookie("token")
    var res = {};
    axios.defaults.baseURL = config.servers["base"];
    
    await axios.put(
        `users/resetpassword/`,
        {
            "current_password": current_password,
            "new_password": new_password
        },
        {
            headers:{
                authorization : "Bearer " + access_token ,
                "Content-Type": "application/json",
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        res = response.data;

    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            res = {
                "success": false,
                "message": error.response.data.message
            } 
        }
    })
    return await res

}