import React from "react";
import Cookies from "../components/cookies";
// import * as locales from "../locales";

export const Context = React.createContext({});

export default class MainProvider extends React.Component {
    constructor(props) {
        super(props);
        const CookieObj = new Cookies();
        let token = CookieObj.getCookie("token");
        let notes_tmp = "";
        const storedUser = localStorage.getItem("user");
        let storedUserData = null;
        if (storedUser) {
            storedUserData = JSON.parse(storedUser);
        }
        const storedBreadcrumbs = localStorage.getItem('breadcrumbs');
        let breadcrumbs = [];
        if (storedBreadcrumbs) {
            breadcrumbs = JSON.parse(storedBreadcrumbs);
        }
        this.state = {
            auth: !!token,
            // user_id: CookieObj.getCookie("UID"),
            setValue: this.setValue,
            updateBreadcrumb: this.updateBreadcrumb,
            logout: this.logout,
            resetLogin: this.resetLogin,
            loading: false,
            sidebar_show: false,
            user_role: storedUserData ? storedUserData.user_role : '',
            companyid: storedUserData ? storedUserData.companyid : false,
            notes: notes_tmp,
            breadcrumb_value: breadcrumbs,
        };
    }

    setValue = (val) => {
        this.setState(val);
    };

    updateBreadcrumb = (newBreadcrumb) => {
        this.setState((prevState) => {
            const breadcrumbIndex = prevState.breadcrumb_value.findIndex(
                (item) => item.name === newBreadcrumb.name
            );
        
            let updatedBreadcrumbs;
        
            if (breadcrumbIndex !== -1) {
                updatedBreadcrumbs = prevState.breadcrumb_value.slice(0, breadcrumbIndex + 1);
            } else {
                updatedBreadcrumbs = [...prevState.breadcrumb_value, newBreadcrumb];
            }

            localStorage.setItem('breadcrumbs', JSON.stringify(updatedBreadcrumbs));
            return { breadcrumb_value: updatedBreadcrumbs };
        });
    };

    logout = () => {
        this.setState({
            auth: false,
            user_role: '',
            companyid: false,
        });
        localStorage.removeItem("user");
        localStorage.removeItem("breadcrumbs");
        const CookieObj = new Cookies();
        CookieObj.removeCookieBatch(["token"]);
        window.location = "/";
    };

    resetLogin = () => {
        this.setState({
            auth: false
        });
        const CookieObj = new Cookies();
        CookieObj.removeCookieBatch(["token"]);
    };

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}
