import axios from "axios";
import Cookies from "../components/cookies";
import config from "../config";


const handleExpiredToken = () => {
    window.location = "/";
}


export const DashboardTemplates = async (company_id) =>{

    const CookieObj = new Cookies();
    let access_token = CookieObj.getCookie("token")
    var dashboard_templates = [];
    axios.defaults.baseURL = config.servers["base"];
    let urlTOload = `company/${company_id}/templates/get/`
    
    await axios.get(
        urlTOload,
        {
            headers:{
                authorization : "Bearer " + access_token 
            }
        }
    ).then((response)=>{
        if(response.status === 401){
            handleExpiredToken();
        }
        dashboard_templates = response.data;
    }).catch(error=>{
        if(error.response.status === 401)
        {   
            handleExpiredToken();
        }
        else{
            dashboard_templates = [] 
        }
    })
    return await dashboard_templates

}